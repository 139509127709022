<template>
  <div class="home">
      <img class="logo-i" src="../assets/index/bg.png" />
      <el-tabs @tab-click="handleClick" v-model="tab" class="topFix">
        <el-tab-pane label="首页"></el-tab-pane>
        <el-tab-pane label="关于我们"></el-tab-pane>
        <el-tab-pane label="加入我们"></el-tab-pane>
      </el-tabs>
    <div class="topBg"></div>
    <div class="icon" @click="show">
      <div class="icon-item"></div>
      <div class="icon-item"></div>
      <div class="icon-item" style="margin-bottom:0;"></div>
    </div>
    <div class="background" v-if="background">
      <div class="zz-top">
        <span class="english">AIC Lab</span>
        <span class="close" @click="closed">x</span>
      </div>
      <div class="tabs">
        <div
          class="tabs-item"
          :class="idx==index?'hover':''"
          @click="checked(item,idx)"
          v-for="(item, idx) in tabList"
          :key="idx"
        >{{item.name}}</div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      background: false,
      index: 0,
      tabList: [
        {
          id: 0,
          name: "首页",
        },
        {
          id: 1,
          name: "关于我们",
        },
        {
          id: 2,
          name: "加入我们",
        }
        // {
        //   id: 3,
        //   name: "解决方案",
        // },
        // {
        //   id: 4,
        //   name: "团队",
        // },
        // {
        //   id: 5,
        //   name: "研究",
        // },
        // {
        //   id: 6,
        //   name: "合作",
        // },
        // {
        //   id: 7,
        //   name: "新闻",
        // },
      ],
      tab:'0'
    };
  },
  methods: {
    handleClick(tab, event) {
      const id = tab.index;
      if (id == 0) {
        this.$router.push("/index");
      } else if (id == 1) {
        this.$router.push("/introduce");
      } else if (id == 2) {
        this.$router.push("/product");
      }
    },
    show() {
      this.background = true;
    },
    closed() {
      this.background = false;
    },
    checked(item, idx) {
      this.index = idx;
      const id = idx;
      if (id == 0) {
        this.$router.push("/index");
      } else if (id == 1) {
        this.$router.push("/introduce");
      } else if (id == 2) {
        this.$router.push("/product");
      } else if (id == 3) {
        this.$router.push("/solution");
      } else if (id == 4) {
        this.$router.push("/team");
      } else if (id == 5) {
        this.$router.push("/research");
      } else if (id == 6) {
        this.$router.push("/cooperate");
      } else if (id == 7) {
        this.$router.push("/news");
      }
      this.background = false
    },
  }
};
</script>

<style scoped lang="less">
@Font: 50;
.home{
  height: 100%;
}
.topBg{
  display: none;
}
.logo-i{
  position: fixed;
  width: 146px;
  height: 36px;
  // left: 373px;
  left: 15vw;
  top: 15px;
  z-index: 1000;
}
.el-tabs {
  position: fixed;
  // top: 20px;
  top:0px;
  // right: 370px;
  z-index: 100;
  width: 100%;
  background:#ffffff;
}
.icon {
  position: fixed;
  // background-color: aqua;
  display: none;
  z-index: 100;
}
.topFix /deep/ .el-tabs__nav{
  float: right;
  margin-right: 17%;
}
/deep/ .el-tabs__nav-wrap::after {
  display: none;
}
/deep/ .el-tabs__item {
  color: #111111;
  font-size: 16px;
  padding: 0 30px;
  //opacity: 45% !important;
  font-weight: bold;
}
/deep/ .el-tabs__active-bar {
  background-color:#115DDB;
  // width: 20px !important;
}
/deep/ .el-tabs__item.is-active {
  color: #115DDB;
  //opacity: 100% !important;
}
/deep/ .el-tabs__header{
  margin:0;
  height: 76px;
}
/deep/ .el-tabs__nav-scroll{
  margin-top: 13px;
}

// 媒体
@media screen and (max-width: 750px) {
  .topBg{
    display: block;
    width: 100%;
    background: #0C31AE;
    position: fixed;
    height: 88rem / @Font;
    top:0;
    z-index: 99;
  }
  .logo-i{
    width: 215rem / @Font;
    height: 60rem / @Font;
    top: 0.2rem;
    left: 1rem;
  }
  .el-tabs {
    display: none;
  }
  .icon {
    display: block;
    width: 40rem / @Font;
    // height: 38rem / @Font;
    top: 25rem / @Font;
    right: 41rem / @Font;
    .icon-item{
      height: 4rem / @Font;
      margin-bottom: 12rem / @Font;
      background: #fff;
    }
  }
  .hover {
    color: #0c31ae;
    border-bottom: 2rem / @Font solid #0c31ae;
  }
  .background {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    color: #000;
    //opacity: 0.95;
    background-color: #fff;
  }
  .zz-top {
    font-size: 32rem / @Font;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #0c31ae;
    padding: 10rem / @Font 20rem / @Font 40rem / @Font 20rem / @Font;
  }
  .english {
    font-weight: 700;
  }
  .tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tabs-item {
    padding: 20rem / @Font 40rem / @Font;
    font-size: 32rem / @Font;
    font-weight: 600;
  }
}
</style>
