<template>
  <div>
    <div class="container">
      <div class="news_content">
        <div class="item"  @click="detail(item.id)" v-for="(item,index) in page.list" :key="index">
          <span>{{ item.title }}</span>
          <span>{{ item.createTime |  formatDate}}</span>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pageDiv">
        <el-pagination
          :total="page.totalCount"
          :page-size="pageCount"
          :current-page.sync="page.currPage"
          layout="total, prev, pager, next"
          @current-change="pageChange"
        ></el-pagination>
      </div>
      <div v-if="loading" class="loading">
        <div class="icon-box">
          <div class="icon"></div>
        </div>
        加载中…
      </div>
      <div v-if="noMore" class="loading">没有更多内容了</div>
    </div>
  </div>
</template>

<script>
let timer
import { newsList, noticeList } from '../../api/news'
import formatDate from '../../utils/dateFilter'
import { getScrollTop, getScrollHeight, getWindowHeight } from '../../api/scroll'
export default {
  name: "Development",
  data() {
    return {
      page: {},
      loading: false,
      noMore: false,
      pageCount: 6
    };
  },
  props:['type'],
  filters:{
    formatDate
  },
  created(){
    if(this.type == 1) {
      newsList(1,6).then(res => {
        this.page = res.page
      })
    }else if(this.type == 2){
      noticeList(1,6).then(res => {
        this.page = res.page
        console.log(res)
      })
    }
  },
  mounted () {
    var medias = window.matchMedia('(max-width: 750px)')
    if (medias.matches) {
      window.addEventListener('scroll', this.funcScroll);
    }
  },
  beforeDestroy(){
    window.removeEventListener('scroll',this.funcScroll)
    window.clearTimeout(timer)
  },
  methods: {
    detail(id) {
      this.$router.push({path:'/newsDetail',query:{id}})
    },
    pageChange(page){
      if(this.type == 1) {
        newsList(page,6).then(res => {
          this.page = res.page
        })
      }else if(this.type == 2){
        noticeList(page,6).then(res => {
          this.page = res.page
        })
      }
    },
    funcScroll(e) { 
      if(Math.abs(Math.round(getScrollTop()) + getWindowHeight() - getScrollHeight()) <= 1){
        if(!timer){
          if(this.page.currPage && this.page.totalPage){
            if(this.page.currPage == this.page.totalPage) {
              this.noMore = true
              timer = setInterval(()=>{
                this.noMore = false
                window.clearTimeout(timer)
                document.body.scrollTop = document.documentElement.scrollTop = document.documentElement.scrollTop-60
                timer=undefined
              },2000)
            }else{
              this.loading = true
              if(this.type == 1) {
                newsList(this.page.currPage+1,this.pageCount).then(res=>{
                  this.page.list.push(res.page.list)
                  this.page.currPage = res.page.currPage
                  this.page.pageSize = res.page.pageSize
                  this.page.totalCount = res.page.totalCount
                  this.page.totalPage = res.page.totalPage
                  this.loading = false
                  window.clearTimeout(timer)
                  document.body.scrollTop = document.documentElement.scrollTop = document.documentElement.scrollTop-60
                })
              }else{
                noticeList(this.page.currPage+1,this.pageCount).then(res=>{
                  this.page.list = this.page.list.concat(res.page.list)
                  this.page.currPage = res.page.currPage
                  this.page.pageSize = res.page.pageSize
                  this.page.totalCount = res.page.totalCount
                  this.page.totalPage = res.page.totalPage
                  this.loading = false
                  window.clearTimeout(timer)
                  document.body.scrollTop = document.documentElement.scrollTop = document.documentElement.scrollTop-60
                })
              }
            }
          }
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
@Font: 50;
.container {
  width: 680px;
  .news_content {
    margin-bottom: 60px;
    .item {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      border-bottom: 1px solid #e7e6ed;
      cursor: pointer;
      span {
        line-height: 32px;
      }
    }
    .item:first-child {
      padding-top: 4px;
    }
  }
  .pageDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 750px) {
  .container{
    width: 100%;
    .pageDiv{
      display: none;
    }
    .news_content{
      margin-bottom: 0;
      .item {
        font-size: 28rem / @Font;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 28rem / @Font;
        padding: 32rem / @Font 0;
      }
      .item:first-child {
          padding-top: 0;
        }
    } 
  }
  .loading {
    display: flex;
    justify-content: center;
    margin: 0.32rem 0 40rem / @Font 0;
    font-size: 24rem / @Font;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7A7E8C;
    line-height: 33rem / @Font;
    .icon-box{
      width: 32rem / @Font;
      height: 32rem / @Font;
      box-sizing: border-box;
      border-radius: 20rem / @Font;
      margin-right: 16rem / @Font;
      background-image: linear-gradient(30deg , #FFFFFF , #0C31AE);
      -webkit-transition-property: -webkit-transform;
      -webkit-transition-duration: 1s;
      -moz-transition-property: -moz-transform;
      -moz-transition-duration: 1s;
      -webkit-animation: rotate 3s linear infinite;
      -moz-animation: rotate 3s linear infinite;
      -o-animation: rotate 3s linear infinite;
      animation: rotate 3s linear infinite;
      .icon{
        width: 16rem / @Font;
        height: 16rem / @Font;
        background: #FFFFFF;
        border-radius: 20rem / @Font;
        margin: 8rem / @Font 0 0 8rem / @Font;
      }
    }
  }
}

</style>