<template>
    <div v-html="reward.details" class="text"></div>
</template>
<script>
import { reward } from '../../api/research'
export default {
  name: "awards",
  data() {
    return {
      reward: {}
    };
  },
  created() {
    reward().then(res => {
      this.reward = res.reward
    })
  },
  methods: {},
};
</script>
<style lang="less" scoped>
@import "../../assets/css/media.css";
@Font: 50;
@media screen and (max-width: 750px) {
  .text{
    font-size: 28rem / @Font;
  }
}
</style>
