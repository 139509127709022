<template>
  <div>
    <div class="footer">
      <span class="span">
        Copyright ©成都乐见数字科技有限公司,
        All Rights Reserved.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang='less'>
@Font: 48;
.footer {
  height: 68px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  background-color:rgba(17, 17, 17, 0.7)
}

@media screen and (max-width: 750px) {
  .footer {
    height: 120rem / @Font;
    text-align: center;
    font-size: 24rem / @Font;
    padding: 0;
    // position: fixed;
    // bottom: 0;
    // width: 100%;
  }
  .span {
    width: 489rem / @Font;
    height: 66rem / @Font;
  }
}
</style>
