<template>
  <div class="footFlex">
    <div class="bg-img"></div>
    <div class="top-content">
      <div class="top-content-bg">
        <img src="../../assets/introduce/微信图片_20210308154657.png">
      </div>
      <div class="top-content-text">
        <div>公司简介</div>
        <div></div>
        <div> 成都乐见数字科技有限公司 成立于2016年，依托成都市丰富的高校资源和成都在西南地区的中心城市地位，公司发展迅速，目前有一支技术成熟稳定过硬的研发团队，主要为各行业（主要聚焦金融行业）客户提供计算机软硬件信息技术和系统集成服务，已经服务过包括吉利金科集团、民生银行、中国邮政、重庆招商局、同创柳工、成飞集团中航无人机、中电十所、成都绿色地球等多家大中小型客户。通过专业的技术服务，获得了广大合作伙伴的信任与赞赏。</div>
        <div>为了更好的服务于客户，持续提高解决方案的创新型及品质感，我们构建了清晰、可视、可持续改进的研发流程按照不同行业、不同类型客户法人需求特点，总结、提炼各类品质项目案例，形成了特有的解决方案。专业化流程、优质的服务、丰富的经验以及对创意品质的追求是客户选择我们的理由。</div>
      </div>
    </div>
    <div class="middle-content">
      <div class="middle-content-padding">
        <div class="middle-content-title">选择我们的理由</div>
        <div class="middle-content-list">
          <div class="list-item">
            <img src="../../assets/introduce/组 46.png">
            <div>敏捷开发</div>
            <div>满足系统快速上线、更新迭代</div>
          </div>
          <div class="list-item" style="margin:54px 166px 0;">
            <img src="../../assets/introduce/组 47.png">
            <div>核心价值</div>
            <div>从客户角度出发，真正解决问题</div>
          </div>
          <div class="list-item">
            <img src="../../assets/introduce/组 48.png">
            <div>流程规范</div>
            <div>开发流程规范、进度透明化</div>
          </div>
          <div class="list-item">
            <img src="../../assets/introduce/组 49.png">
            <div>质量保证</div>
            <div>团队稳定、高效协作、整体把控</div>
          </div>
          <div class="list-item">
            <img src="../../assets/introduce/组 50.png">
            <div>及时维护</div>
            <div>有问题及时维护，免去后顾之忧</div>
          </div>
          <div class="list-item">
            <img src="../../assets/introduce/组 51.png">
            <div>客户关系</div>
            <div>定期回访，共同面对问题与挑战</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-content">
      <div class="bottom-content-padding">
        <div class="bottom-content-title">专业、高效的开发流程</div>
        <div class="bottom-content-list">
          <div class="list-item">
            <div>确定需求</div>
            <div>高级产品经理对接，理清开发需求，并从技术实现、用户体验等多方面分析。</div>
          </div>
          <div class="list-item">
            <div>项目策划</div>
            <div>从多角度出发，合理规划整个项目，确定时间节点，保证项目顺利进行。</div>
          </div>
          <div class="list-item">
            <div>产品研发</div>
            <div>专业开发者进行架构设计，功能开发，第三方服务对接调试，实现所有需求。</div>
          </div>
          <div class="list-item">
            <div>测试上线</div>
            <div>内部多轮兼容测试、性能测试、漏洞修复，客户验收测试，优化满意再上线。</div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="foot"></Footer>
  </div>
</template>

<script>
import Footer from '../../components/footer'
export default {
  name: "introduce",
  components: {
    Footer
  },
  data() {
    return {
      position: 'right'
    };
  },
  created() {

  },
  mounted () {
    var medias = window.matchMedia('(max-width: 750px)')
    console.log(medias.matches)
    if (medias.matches) {
      this.position = 'top'
    }
  },
};
</script>

<style scoped lang="less">
@Font: 50;
.footFlex{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .foot{
    margin-top: auto;
  }
}
.bg-img {
  height: 380px;
  background: url("../../assets/introduce/2.png");
  background-size: cover;
  background-size: 100% 100%;
}
.top-content {
  padding: 100px 0 146px;
  margin: 0 auto;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  .top-content-bg{
    width: 810px;
    height: 386px;
    // background: url("../../assets/introduce/微信图片_20210308154657.png");
    margin-right: 5%;
  }
  .top-content-text{
    width: 669px;
    :first-child{
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(17, 17, 17, 0.85);
    }
    :nth-child(2){
      width: 32px;
      height: 2px;
      background: #115DDB;
      margin:4px 0 40px 0;
    }
    :nth-child(3),:nth-child(4){
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 28px;
      color: rgba(17, 17, 17, 0.85);
    }
    :nth-child(3){
      margin-bottom: 24px;
    }
  }
}
.middle-content{
  padding: 56px 0 56px;
  background: rgba(17, 93, 219, 0.03);
  .middle-content-title{
    text-align: center;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(17, 17, 17, 0.85);
  }
  .middle-content-list{
    margin-top: 34px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1102px;
    margin: 0 auto;
    .list-item{
      display: flex;
      justify-content: center;
      width: 200px;
      flex-wrap: wrap;
      margin-top: 54px;
      img{
        width: 117px;
      }
      div{
        width: 100%;
        text-align: center;
      }
      :nth-child(2){
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(17, 17, 17, 0.85);
      }
      :nth-child(3){
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(17, 17, 17, 0.85);
      }
    }
  }
}
.bottom-content{
  padding: 130px 0 141px;
  .bottom-content-title{
    text-align: center;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(17, 17, 17, 0.85);
  }
  .bottom-content-list{
    margin-top: 34px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1280px;
    margin: 0 auto;
    .list-item{
      width: 266px;
      margin-top: 54px;
      text-align: center;
      padding-top: 105px;
      :nth-child(1){
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(17, 17, 17, 0.85);
      }
      :nth-child(2){
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(17, 17, 17, 0.85);
        margin-top: 29px;
      }
    }
  }
  .bottom-content-list .list-item:nth-child(1){
    background:url("../../assets/introduce/01.png");
    background-repeat: no-repeat;
    background-size: 132px;
    background-position-x: 63px;
    background-position-y: 14px;
  }
  .bottom-content-list .list-item:nth-child(2){
    background:url("../../assets/introduce/02.png");
    background-repeat: no-repeat;
    background-size: 132px;
    background-position-x: 63px;
    background-position-y: 14px;
  }
  .bottom-content-list .list-item:nth-child(3){
    background:url("../../assets/introduce/03.png");
    background-repeat: no-repeat;
    background-size: 132px;
    background-position-x: 63px;
    background-position-y: 14px;
  }
  .bottom-content-list .list-item:nth-child(4){
    background:url("../../assets/introduce/04.png");
    background-repeat: no-repeat;
    background-size: 132px;
    background-position-x: 63px;
    background-position-y: 14px;
  }

}
.nav {
  padding: 40px 0 32px 0;
}
/deep/ .el-tabs__nav-wrap::after {
  display: block;
}
/deep/ .el-tabs--right .el-tabs__header.is-right {
  margin: 0;
  float: none;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  font-size: 22px;
  color: #000;
}
/deep/ .el-tabs__active-bar {
  width: 4px !important;
  background-color: #0C31AE;
}
/deep/ .el-tabs__item.is-active {
  color: #0C31AE;
}


// 媒体
@media screen and (max-width: 750px) {
  /deep/ .el-tabs__nav-scroll {
    overflow-x: auto !important;
    font-size: 32rem / @Font;
  }
  .el-tabs__nav-scroll::-webkit-scrollbar {
    display: none !important;
  }
  /deep/ .el-tabs__active-bar {
    width: 64rem / @Font !important;
    height: 6rem / @Font !important;
  }
  /deep/ .el-tabs__active-bar{
    left: 30rem / @Font;
  }
  /deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2){
    padding: 0 0.8rem !important;
  }
  /deep/ .el-tabs__nav-wrap.is-scrollable{
    padding: 0;
  }
  /deep/ .el-tabs__nav {
    padding: 28rem / @Font 0;
    background-color: #F1F0F6;
  }
  /deep/ .el-tabs__item {
    font-size: 32rem / @Font;
    height: 45rem / @Font;
    line-height: 45rem / @Font;
    padding: 0 40rem / @Font;
  }
  .nav {
    display: none;
  }
  .bg-img  {
    display: block;
    height: 100%;
  }
  .content {
    width: auto;
    margin: 0;
    padding-bottom: 56rem / @Font;
  }
}
</style>
