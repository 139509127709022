import request from '@/utils/request'

export function list(page,limit) {
    return request({
        url: `/web/cooperate/list?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

export function detail(id) {
    return request({
        url: `/web/cooperate/${id}`,
        method: 'get',
    })
}