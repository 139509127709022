<template>
  <div class="footFlex">
    <div class="bg-img"></div>
    <div class="top-content">
      <div class="top-content-text">
        <div>{{project.title}}</div>
    </div>
      <dev class="top-content-text">
        {{project.content}}
      </dev>
    </div>
    <Footer class="foot"></Footer>
  </div>
</template>

<script>
import Footer from '../../components/footer'
import {project} from "../../api/product"
export default {
  name: "productDetail",
  components: {
    Footer
  },
  data() {
    return {
      project:{},
      id: ''
    };
  },
  created() {
    var that = this
    this.id = this.$route.query.id
    project(this.id).then(res=>{
      this.project = res.projectVO
    })
  },
  mounted () {
    var medias = window.matchMedia('(max-width: 750px)')
    console.log(medias.matches)
    if (medias.matches) {
      this.position = 'top'
    }
  },
};
</script>

<style scoped lang="less">
@Font: 50;
.footFlex{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .foot{
    margin-top: auto;
  }
}
.bg-img {
  height: 380px;
  background: url("../../assets/introduce/蒙版组 13@2x.png");
  background-size: cover;
  background-size: 100% 100%;
}
.top-content {
  padding: 100px 0 146px;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 16vw;
  margin-right: 17%;

  // margin: 0 auto;
  // width: 1200px;
  display: flex;
  flex-wrap: wrap;
  .top-content-bg{
    width: 490px;
    height: 386px;
    background: url("../../assets/introduce/组 45.png");
    margin-right: 120px;
  }
  .top-content-text{
    // width: 1180px;
    :first-child{
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(17, 17, 17, 0.85);
    }
    :nth-child(2){
      width: 32px;
      height: 2px;
      background: #115DDB;
      margin:4px 0 40px 0;
    }
    :nth-child(3),:nth-child(4){
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 28px;
      color: rgba(17, 17, 17, 0.85);
    }
    :nth-child(3){
      margin-bottom: 24px;
    }
  }
}
.middle-content{
  padding: 56px 0 56px;
  background: rgba(17, 93, 219, 0.03);
  .middle-content-title{
    text-align: center;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(17, 17, 17, 0.85);
  }
  .middle-content-list{
    margin-top: 34px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1102px;
    margin: 0 auto;
    .list-item{
      display: flex;
      justify-content: center;
      width: 200px;
      flex-wrap: wrap;
      margin-top: 54px;
      img{
        width: 117px;
      }
      div{
        width: 100%;
        text-align: center;
      }
      :nth-child(2){
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(17, 17, 17, 0.85);
      }
      :nth-child(3){
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(17, 17, 17, 0.85);
      }
    }
  }
}
.bottom-content{
  padding: 130px 0 141px;
  .bottom-content-title{
    text-align: center;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(17, 17, 17, 0.85);
  }
  .bottom-content-list{
    margin-top: 34px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1280px;
    margin: 0 auto;
    .list-item{
      width: 266px;
      margin-top: 54px;
      text-align: center;
      padding-top: 105px;
      :nth-child(1){
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(17, 17, 17, 0.85);
      }
      :nth-child(2){
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(17, 17, 17, 0.85);
        margin-top: 29px;
      }
    }
  }
  .bottom-content-list .list-item:nth-child(1){
    background:url("../../assets/introduce/01.png");
    background-repeat: no-repeat;
    background-size: 132px;
    background-position-x: 63px;
    background-position-y: 14px;
  }
  .bottom-content-list .list-item:nth-child(2){
    background:url("../../assets/introduce/01.png");
    background-repeat: no-repeat;
    background-size: 132px;
    background-position-x: 63px;
    background-position-y: 14px;
  }
  .bottom-content-list .list-item:nth-child(3){
    background:url("../../assets/introduce/03.png");
    background-repeat: no-repeat;
    background-size: 132px;
    background-position-x: 63px;
    background-position-y: 14px;
  }
  .bottom-content-list .list-item:nth-child(4){
    background:url("../../assets/introduce/04.png");
    background-repeat: no-repeat;
    background-size: 132px;
    background-position-x: 63px;
    background-position-y: 14px;
  }
  
}
.nav {
  padding: 40px 0 32px 0;
}
/deep/ .el-tabs__nav-wrap::after {
  display: block;
}
/deep/ .el-tabs--right .el-tabs__header.is-right {
  margin: 0;
  float: none;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  font-size: 22px;
  color: #000;
}
/deep/ .el-tabs__active-bar {
  width: 4px !important;
  background-color: #0C31AE;
}
/deep/ .el-tabs__item.is-active {
  color: #0C31AE;
}


// 媒体
@media screen and (max-width: 750px) {
  /deep/ .el-tabs__nav-scroll {
    overflow-x: auto !important;
    font-size: 32rem / @Font;
  }
  .el-tabs__nav-scroll::-webkit-scrollbar {
    display: none !important;
  }
  /deep/ .el-tabs__active-bar {
    width: 64rem / @Font !important;
    height: 6rem / @Font !important;
  }
  /deep/ .el-tabs__active-bar{
    left: 30rem / @Font;
  }
  /deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2){
    padding: 0 0.8rem !important;
  }
  /deep/ .el-tabs__nav-wrap.is-scrollable{
    padding: 0;
  }
  /deep/ .el-tabs__nav {
    padding: 28rem / @Font 0;
    background-color: #F1F0F6;
  }
  /deep/ .el-tabs__item {
    font-size: 32rem / @Font;
    height: 45rem / @Font;
    line-height: 45rem / @Font;
    padding: 0 40rem / @Font;
  } 
  .nav {
    display: none;
  }
  .bg-img  {
    display: block;
    height: 100%;
  }
  .content {
    width: auto;
    margin: 0;
    padding-bottom: 56rem / @Font;
  }
}
</style>
