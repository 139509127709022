<template>
  <div class="footFlex">
    <div class="bg-img">
      <div class="logoimg">
        <div class="logo-top">
          <div class="j-img">
            <img src="../../assets/team/编组 10.png" alt />
          </div>
          <div>
            <div>
              <img class="logo-i" src="../../assets/laboratory/Logo.png" alt />
            </div>
            <div class="english-tit">
              <img class="tit-i" src="../../assets/team/科研团队标题.png" alt />
            </div>
          </div>
        </div>
        <div class="color-t">
          <img src="../../assets/product/彩条.png" alt />
        </div>
      </div>
      <div class="bigimg"><span></span></div>
    </div>
    <div class="content-box">
      <div class="navigation">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            <a>团队</a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="item-content">
          <div class="left-content">智能实验室共有研究人员38人，其中博士人数30人，硕士4名，本科4名。毕业于电子科技大学、四川大学、清华大学等国内知名高校。<br>领导团队为实验室主任、首席科学家陈博教授，副主任方科、潘灵、戴永明皆为海归科学家。</div>
          <div class="mobile-icon"></div>
          <div class="right-content">
            <div class="item-right" @click="detail(item.id)" v-for="(item,index) in page.list" :key="index">
              <!-- <div class="img-item" :style="`background:url(http://47.92.65.216:8080/agile/sys/uploadDown/downImg?fileName=${item.image}) no-repeat`"></div> -->
              <!-- <div class="img-item" :style="`background:url(http://localhost:8080/lejiansoftwebbackwebback_war/sys/uploadDown/downImg?fileName=${item.image}) no-repeat`"></div> -->
              <div class="img-item" :style="`background:url(http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${item.image}) no-repeat`"></div>
              <div class="text-item">
                <div>
                  <div class="name-text">{{ item.name }}</div>
                  <div class="move-text"></div>
                </div>
                <div style="clear:both;"></div>
                <div class="pinyin-text">{{ item.pinyin }}</div>
                <div class="position-text">{{ item.title }}</div>
                <div class="detail-text" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="foot"></Footer>
  </div>
</template>

<script>
import Footer from "../../components/footer";
import { list } from "../../api/team"
import { pinyin } from "../../utils/Convert_Pinyin"
export default {
  name: "team",
  components: {
    Footer,
  },
  data() {
    return {
      page: {}
    };
  },
  created() {
    list(1,4).then( res=> {
      this.page = res.page
      this.page.list.forEach(item=>{
        item.pinyin = pinyin.getFullChars(item.name)
      })
    })
  },
  mounted () {
    
  },
  methods: {
    detail(id) {
      this.$router.push({path:'/teamDetail',query:{id}})
    }
  },
};
</script>

<style scoped lang='less'>
@import "../../assets/css/media.css";
@Font: 50;
.footFlex{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .foot{
    margin-top: auto;
  }
}
.bg-img {
  display: flex;
  height: 340px;
}
.logoimg {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo-top {
  height: 300px;
  background-color: #0c31ae;
  padding: 20px 0 0 15vw;
  position: relative;
}
.english-tit {
  padding-top: 41px;
}
.j-img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.logo-i {
  width: 162px;
  height: 45px;
  opacity: 0;
}

.color-t {
  display: flex;
  justify-content: flex-end;
  width: 400px;
  padding-left: 370px;
}
.bigimg {
  flex: 2;
  background: url(../../assets/team/VCG21409095263.png);
  background-size: cover;
  position: relative;
  span {
      position: absolute;
      bottom: 0;
      width: 40px;
      height: 40px;
      background-color: #16E4DC;
  }
}
.content-box {
  width: 1280px;
  margin: 0 auto;
}
.navigation {
  padding: 40px 0 32px 0;
}
.content {
  padding-bottom: 80px;
}
.item-content {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}
.left-content{
  flex: 1;
  font-weight: 500;
  color: #222222;
  line-height: 36px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  margin-right: 20px;
}
.mobile-icon{
  display: none;
}
.right-content{
  flex: 2;
  .item-right{
    display: flex;
    margin-bottom: 32px;
    :hover{
      cursor: pointer;
    }
    .text-item{
      flex: 556;
      border-bottom: 1px solid #E7E6ED;
      .name-text{
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 40px;
        float: left;
      }
      .move-text{
        background: url(../../assets/team/跳转大.png);
        float: right;
        width: 20px;
        height: 20px;
        background-size: cover;
      }
      .pinyin-text{      
        font-size: 20px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #222222;
        line-height: 24px;
        margin:4px 0 17px 0;
      }
      .position-text{       
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #03C0C9;
        line-height: 22px;
        margin-bottom: 24px;
      }
      .detail-text{ 
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6C6F7B;
        line-height: 28px;
        height: 84px;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        // text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        /deep/ p{
          margin: 0;
        }
      }
    }
    .img-item{
      // background: url(../../assets/timg.jpg);
      background-size: auto 90% !important;
      background-color: rgba(12,49,174,0.1) !important;
      background-position: center 100% !important;
      overflow: hidden;
      height: 240px;
      flex: 200;
      margin-right: 24px;
    }
  }
}
.item {
  width: 32%;
  height: 425px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0 0 5px #eee;
}
.bgi {
  flex: 6;
  background: url(../../assets/timg.jpg);
  background-size: cover;
  overflow: hidden;
}
.text-box {
  flex: 2;
  // padding: 30px 30px 20px 10px;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  font-size: 28px;
  font-weight: 700;
  color: #222222;
  background: #ffffff;
  width: 224px;
  padding: 6px 8px 6px 8px;
  margin: 10px 0 0 10px;
}
.text {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  // text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 20px;
  font-weight: 400;
  color: #6C6F7B;
  line-height: 36px;
}
.xiangq {
  color: #0C31AE;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  background: #ffffff;
  width: 76px;
  padding: 6px 8px 6px 8px;
  margin-left: 10px;
}
.span-i {
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.pageDiv {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

/* 媒体 */
@media screen and (max-width: 750px) {
  .navigation {
    display: none;
  }
  .bg-img {
    display: block;
    height: 100%;
  }
  .content {
    padding-bottom: 0;
  }
  .item-content {
    display: block;
  }
  .left-content{
    line-height: 56rem / @Font;
    font-size: 34rem / @Font ;
    margin-right: 32rem / @Font;
  }
  .mobile-icon{
    display: block;
    width: 32rem / @Font;
    height: 1rem / @Font;
    background: #222222;
    margin: 31rem / @Font 0 56rem / @Font 0;
  }
  .right-content{
    .item-right{
      margin-bottom: 32rem / @Font;
      .text-item{
        flex: 426;
        border-bottom: 1px solid #E7E6ED;
        .name-text{
          font-size: 48rem / @Font;
          line-height: 67rem / @Font;
        }
        .move-text{
          width: 25rem / @Font;
          height: 25rem / @Font;
        }
        .pinyin-text{      
          font-size: 28rem / @Font;
          line-height: 32rem / @Font;
          margin:6rem / @Font 0 63rem / @Font 0;
        }
        .position-text{       
          font-size: 28rem / @Font;
          line-height: 40rem / @Font;
          margin: 0;
        }
        .detail-text{ 
          display: none;
        }
      }
      .img-item{
        height: 240rem / @Font;
        margin-right: 32rem / @Font;
        flex: 200;
        // background-size: 68% !important;
      }
    }
  }
  .logo-top {
    height: 280rem / @Font;
    padding: 15rem / @Font 0 30rem / @Font 50rem / @Font;
  }
  .logo-i {
    width: 215rem / @Font;
    height: 60rem / @Font;
    // opacity: 1;
  }
  .tit-i {
    width: 291rem / @Font;
    height: 168rem / @Font;
  }
  .english-tit {
    padding-top: 40rem / @Font;
  }
  .j-img {
    display: none;
  }
  .color-t {
    display: none;
  }
  .bigimg {
    height: 320rem / @Font;
    display: none;
  }
  .content-box {
    width: auto;
    margin: 56rem / @Font 46rem / @Font 0;
  }
  .item {
    width: 100%;
    display: block;
    height: auto;
    margin-bottom: 12rem / @Font;
    box-shadow: none;
  }
  .bgi {
    height: 346rem / @Font;
  }
  .text-box {
    padding: 62rem / @Font 0 36rem / @Font 12rem / @Font;
  }
  .title {
    font-size: 48rem / @Font;
    width: 386rem / @Font;
  }
  .text {
    font-size: 34rem / @Font;
    margin: 42rem / @Font 0 61rem / @Font;
  }
  .xiangq {
    font-size: 24rem / @Font;
    width: 152rem / @Font;
  }
  .span-i {
    padding-left: 20rem / @Font;
  }
  .span-i > img {
    width: 25rem / @Font;
    height: 25rem / @Font;
  }

  // 分页
  .pageDiv {
    padding-bottom: 0;
    padding: 60rem / @Font 0 72rem / @Font 0;
    display: none;
  }
  .el-pagination {
    width: 649rem / @Font;
    height: 40rem / @Font;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/ .el-pagination .btn-prev {
    padding: 0;
  }
  /deep/ .el-pagination .btn-next {
    padding: 0;
  }
  /deep/ .el-pagination__total {
    font-size: 32rem / @Font !important;
    margin-right: 10rem / @Font;
  }
  /deep/ .el-pager .number {
    font-size: 32rem / @Font !important;
  }
  /deep/ .el-icon {
    font-size: 32rem / @Font !important;
  }
}
</style>
