import { render, staticRenderFns } from "./patent.vue?vue&type=template&id=5cc0c2fd&scoped=true&"
import script from "./patent.vue?vue&type=script&lang=js&"
export * from "./patent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cc0c2fd",
  null
  
)

export default component.exports