<template>
  <div>
    <div class="background">
      <div class="swip" @mouseenter="come" @mouseleave="leave">
        <div class="swiper-container swiper1">
          <div class="swiper-wrapper">
            <div v-for="(el,index) in arrItem" :key="index" class="swiper-slide" @click="newsDetail(el.id)" >
              <div class="imgbox">
                <img :src="`http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${el.image}`" class="swiperImg" />
              </div>
            </div>
          </div>
          <!-- <div class="bgc swiper-button-prev swiper-button-white" v-show="hidden" @click="back"></div>
          <div class="bgc swiper-button-next swiper-button-white" v-show="hidden" @click="next"></div> -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 主体 -->
      <div class="under">
        <!-- 图片 -->
        <div class="pictures">
          <div class="pictures-left"
          :style="`background:url(http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${business[0].image})`">
            <div class="pictures-content">
              <div>{{business[0].title}}</div>
              <div class="pictures-block"></div>
              <div class="pictures-other">
                <!-- 连接用户、商户和金融机构，提供安全、专业的金融产品与服务 -->
                {{business[0].content}}
              </div>
            </div>
          </div>
          <div class="pictures-right">
            <div class="pictures-right-1" :style="`background:url(http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${business[1].image})`">
              <div class="pictures-content">
                <div>{{business[1].title}}</div>
                <div class="pictures-block"></div>
                <div class="pictures-other">
                {{business[1].content}}</div>
              </div>
            </div>
            <div class="pictures-right-2" :style="`background:url(http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${business[2].image})`">
              <div class="pictures-content">
                <div>{{business[2].title}}</div>
                <div class="pictures-block"></div>
                <div class="pictures-other">
                {{business[2].content}}</div>
              </div>
            </div>
            <div class="pictures-right-3" :style="`background:url(http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${business[3].image})`">
              <div class="pictures-content">
                <div>{{business[3].title}}</div>
                <div class="pictures-block"></div>
                <div class="pictures-other">
                {{business[3].content}}</div>
              </div>
            </div>
            <div class="pictures-right-4" :style="`background:url(http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${business[4].image})`">
              <div class="pictures-content">
                <div>{{business[4].title}}</div>
                <div class="pictures-block"></div>
                <div class="pictures-other">
                {{business[4].content}}</div>
              </div>
            </div>
            <div class="pictures-right-5" :style="`background:url(http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${business[5].image})`">
              <div class="pictures-content">
                <div>{{business[5].title}}</div>
                <div class="pictures-block"></div>
                <div class="pictures-other">
                {{business[5].content}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="m-content">
      <div class="m-under">
        <!-- 成功案例 -->
        <div class="cp">
          <div class="cp-title">
            <div class="cp-say">成功案例<span>case</span></div>
          </div>
          <div class="cp-div">
            <div class="swiper-container swiper2">
              <div class="swiper-wrapper">
                <div v-for="(el,index) in cpArr" :key="index" class="swiper-slide" @click="productDetail(el.id)">
                  <div class="imgbox">
                    <div :style="`background:url(http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${el.image1}) no-repeat`" class="swiperImg productImg"></div>
                  </div>
                  <div class="cp-text">
                    <div class="cp-text-title">{{el.title}}</div>
                    <div class="cp-text-content">{{el.describe}}</div>
                    <div class="cp-text-button">了解详情</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="text-align:center;margin: 56px auto 0;height: 35px;width: 176px;">
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作企业 -->
    <div class="c-content">
      <div class="m-under">
        <div class="cp">
          <div class="cp-title">
            <div class="cp-say">合作企业<span>Cooperate</span></div>
          </div>
          <div class="cooperate-pic">
            <img src="../../assets/index/组 67.png" width="1280px" height="400px">
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="f-content">

        <div class="cp-title">
          <div class="cp-say">联系我们 <span>Contact</span></div>
        </div>
        <div class="f-flex">
          <div class="kefu">
            <!-- <div class="f-title">
              <div style="margin-bottom: 10px;color: #2B2682;">
                <div>想了解更多项目</div>
                <div>请联系我们</div>
              </div>
              <div style="margin-bottom: 40px;color: #2B2682;">Please contact us for more details</div>
            </div> -->
            <div class="add-cont">
              <div class="youb">
                <span style="margin-right: 20px">邮编</span>
                <span>610036</span>
              </div>
              <div class="qq">
                <span style="margin-right: 20px">邮箱</span>
                <span>nicolas@lejiansoft.com</span>
              </div>
              <div class="phone">
                <span style="margin-right: 20px">电话</span>
                <span style="text-decoration:underline;color: #0D43BD;">15928120200</span>
              </div>
              <div class="address">
                <span style="margin-right: 20px">地址</span>
                <div>
                  <span>四川省成都市郫都区西芯大道5号汇都总部园1期2栋602</span>
                </div>
              </div>
            </div>
          </div>
          <baidu-map class="gis" ak="H9e6FKnGy80pvXRfYKkUYnk41wzXRuZE" :zoom="map.zoom" :center="{lng: map.center.lng, lat: map.center.lat}">
            <!--比例尺控件-->
            <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
            <!--缩放控件-->
            <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" ></bm-navigation>
            <!--聚合动态添加的点坐标-->
            <bm-marker-clusterer :averageCenter="true">
                <bm-marker :position="{lng: map.center.lng, lat: map.center.lat}"></bm-marker>
            </bm-marker-clusterer>
          </baidu-map>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Swiper from 'swiper'
import Footer from "../../components/footer";
import { homeNews, homeTeam, homeProduct, homeSolution,homeBusiness } from '../../api/home'
import formatDate from '../../utils/dateFilter'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmScale from 'vue-baidu-map/components/controls/Scale'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation'
import BmMarkerClusterer from  'vue-baidu-map/components/extra/MarkerClusterer'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'
export default {
  name: "index",
  components: {
    Footer,
    BaiduMap,
    BmScale,
    BmNavigation,
    BmMarkerClusterer,
    BmMarker
  },
  data() {
    return {
      bg:'../../assets/index/头像1.png',
      boolen: true,
      arrItem: [

      ],
      cpArr: [
        {
          name: "1",
          imgUrl: require("../../assets/index/产品1.png"),
        },
        {
          name: "2",
          imgUrl: require("../../assets/index/产品1.png"),
        },
        {
          name: "3",
          imgUrl: require("../../assets/index/产品1.png"),
        },
        {
          name: "4",
          imgUrl: require("../../assets/index/产品1.png"),
        },
      ],
      arr: [
        {
          title: "RTFrame",
        },
        {
          title: "RTFrame",
        },
        {
          title: "RTFrame",
        },
        {
          title: "RTFrame",
        },
      ],
      solutionList:[],
      team:[{name:'',title:'',desc:''},{name:'',title:'',desc:''},{name:'',title:'',desc:''}],
      hidden: false,
      swiper: "",
      swiperWidth: 1,
      swiperBut: "",
      swiperButWidth: 3,
      map:{
          center: {lng: 103.971406,lat:30.743781},
          zoom: 17
      },
      business:[{},{},{},{},{},{}],
    };
  },
  filters:{
    formatDate
  },
  created(){
    homeBusiness().then(res=>{
      this.business = res.list
    })
    homeTeam().then(res=>{
      this.team = res.list
    })
    homeNews().then(res=>{
      this.arrItem = res.list
    })
    homeProduct().then(res=>{
      console.log("homeProduct : ",res.list)
      this.cpArr = res.list
      // this.cpArr = this.cpArr.concat(res.list)
    })
    homeSolution().then(res=>{
      this.solutionList = res.list
    })
  },
  mounted: function () {
    this.isMedia();
    this.swip();
    this.swipB();
  },
  methods: {
    newsDetail(id){
      // this.$router.push({path:'/newsDetail',query:{id}})
    },
    productDetail(id){
      this.$router.push({path:'/productDetail',query:{id}})
    },
    solutionDetail(id){
      this.$router.push({path:'/solutionDetail',query:{id}})
    },
    teamDetail(id){
      this.$router.push({path:'/teamDetail',query:{id}})
    },
    delHtmlTag(str){
      return str.replace(/<[^>]+>/g,"");
    },
    isMedia() {
      var medias = window.matchMedia("(max-width: 750px)");
      console.log(medias.matches);
      if (medias.matches) {
        this.swiperWidth = 1.6;
        this.swiperButWidth = 1;
        this.boolen = false;
      }
    },
    swip() {
      let _this = this;
      var mySwiper1 = new Swiper(".swiper1", {
        autoplay: 3000,
        height: 520,
        slidesPerView: _this.swiperWidth,
        observer: true,
        observeParents: true,
        spaceBetween: 20,
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        //   hideOnClick: true,
        // },
        pagination : '.swiper-pagination',
        paginationClickable :true,
      });
      this.swiper = mySwiper1;
    },
    swipB() {
      let _this = this;
      var mySwiper2 = new Swiper(".swiper2", {
        slidesPerView: _this.swiperButWidth,
        observer: true,
        observeParents: true,
        spaceBetween: 40,
        pagination : '.swiper-pagination',
        paginationClickable: true,
        uniqueNavElements :false,
        height:266,
      });
      this.swiperBut = mySwiper2;
    },
    come() {
      this.hidden = true;
    },
    leave() {
      this.hidden = false;
    },
    back() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    },
    start(e) {
      console.log(e.changedTouches[0].pageX);
    },
    muve(e) {
      console.log(e.changedTouches[0].pageX);
    },
  },
};
</script>

<style scoped lang='less'>
@import "../../../node_modules/swiper/dist/css/swiper.css";
@Font: 50;
.solution-item{
  display: flex;
  justify-content: space-between;
  min-height: 71px;
  .it-eng{
    font-size: 24px;
    font-weight: 600;
    width: 134px;
  }
  .more{
    color: #0c31ae;
    font-size: 12px;
  }
}
.footFlex{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .foot{
    margin-top: auto;
  }
}
.background {
  height: 540px;
  margin-top: 76px;
}
.backg-text {
  margin-left: 370px;
}
.Logo {
  padding-top: 20px;
  padding-bottom: 80px;
}
.logo-i {
  width: 162px;
  // height: 45px;
  height: 20px;
  opacity: 0;
}
.title-i {
  width: 415px;
  height: 151px;
}
.content {
  width: 1280px;
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
  padding-bottom: 100px;
}
.rtive {
  top: -200px;
  position: absolute;
  width: 100%;
  height: 400px;
  display: flex;
}
.back-left {
  flex: 1;
  background: url("../../assets/index/video.png");
  background-size: cover;
  display: flex;
  align-items: flex-end;
}
.left-text {
  padding: 30px 50px 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.smoll-t {
  color: #fff;
  font-size: 24px;
}
.fuh {
  padding: 10px 0;
  color: #00afff;
  font-size: 24px;
}
.smoll-j {
  color: #ffffff;
}
.back-right {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.right-item {
  width: 50%;
  position: relative;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.itemcss {
  top: 20px;
  left: 20px;
  position: absolute;
}
.topnum {
  font-size: 24px;
  font-weight: 700;
  color: #0c31ae;
}
.toptit {
  color: #0c31ae;
}
.botnum {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}
.bottit {
  color: #fff;
}

.city {
  background: url("../../assets/index/city.png");
  background-size: cover;
}
.school {
  background-color: #ccd2f5;
}
.money {
  background-color: #1f1898;
}
.result {
  background-color: #00afff;
}
.m-img {
  display: flex;
  justify-content: flex-end;
  margin-right: 5%;
  margin-bottom: 5%;
}
.r-img {
  display: flex;
  justify-content: flex-end;
  margin-right: 5%;
  margin-bottom: 5%;
}

//
.under {
  padding-top: 80px;
}
.unf {
  margin-bottom: 30px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}
.xinwen {
  font-size: 24px;
  font-weight: 700;
}
.all {
  color: #0c31ae;
  font-size: 12px;
  cursor: pointer;
}
.swiper-slide {
  height: 540px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px #eee;
  margin-top: 10px;
  cursor: pointer;
  transition: margin-top 0.5s;
}
.cp-div .swiper-slide {
  height: 494px;
}
.imgbox {
  flex: 1;
  overflow: hidden;
}
.cp-div .imgbox{
  height: 266px;
  flex: none;
}
.swiperImg {
  width: 100%;
  height: 100%;
}
.productImg{
  background-position: center !important;
  background-color:#e7e9ed !important;
}
.text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px;
}
.guif {
  font-size: 20px;
}
.hour {
  font-size: 12px;
  color: #7a7e8c;
}
.bgc {
  background-color: rgb(39, 62, 80);
}
// 图片
.pictures{
  display: flex;
  justify-content: space-between;
  width: 1280px;
  .pictures-left{
    position: relative;
    width: 300px;
    height: 600px;
    background: url("../../assets/index/组 39.png");
    cursor: pointer;
  }
  .pictures-right{
    width: 960px;
    height: 600px;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
    .pictures-right-1{
      position: relative;
      width: 644px;
      height: 340px;
      background: url("../../assets/index/组 40.png");
      cursor: pointer;
    }
    .pictures-right-2{
      position: relative;
      width: 296px;
      height: 340px;
      background: url("../../assets/index/组 41.png");
      cursor: pointer;
    }
    .pictures-right-3{
      position: relative;
      width: 270px;
      height: 240px;
      background: url("../../assets/index/组 44.png");
      cursor: pointer;
    }
    .pictures-right-4{
      position: relative;
      width: 270px;
      height: 240px;
      background: url("../../assets/index/组 43.png");
      cursor: pointer;
    }
    .pictures-right-5{
      position: relative;
      width: 380px;
      height: 240px;
      background: url("../../assets/index/组 42.png");
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
    }
  }
}
.pictures-content{
  position: absolute;
  left: 28px;
  bottom: 40px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  .pictures-block{
    width: 32px;
    height: 3px;
    background: #FFFFFF;
    margin-top: 4px;
  }
}
.pictures-other{
  padding: 0px 38px 0 0px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 24px;
  color: #FFFFFF;
  margin-top: 10px;
  display: none;
}
.pictures-left:hover .pictures-other{
  display: block;
}
.pictures-left:hover .pictures-block{
  display: none;
}
.pictures-right-1:hover .pictures-other{
  display: block;
}
.pictures-right-1:hover .pictures-block{
  display: none;
}
.pictures-right-2:hover .pictures-other{
  display: block;
}
.pictures-right-2:hover .pictures-block{
  display: none;
}
.pictures-right-3:hover .pictures-other{
  display: block;
}
.pictures-right-3:hover .pictures-block{
  display: none;
}
.pictures-right-4:hover .pictures-other{
  display: block;
}
.pictures-right-4:hover .pictures-block{
  display: none;
}
.pictures-right-5:hover .pictures-other{
  display: block;
}
.pictures-right-5:hover .pictures-block{
  display: none;
}
.peo {
  flex: 1;
  background-color: #0c31ae;
}
.photo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
  color: #ffffff;
  height: 260px;
}
.chenbo {
  font-size: 18px;
  margin-bottom: 10px;
}
.zhuren {
  font-size: 14px;
}
.xiangxi {
  font-size: 14px;
}
.people3 {
  flex: 1;
  display: flex;
}
.renwu3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  cursor: pointer;
}
.p-img {
  flex: 1;
  // background: url(../../assets/index/头像2.png);
  background-size: 70% !important;
  background-color: rgba(12,49,174,0.1) !important;
  background-position: center 20px !important;
}
.d-img {
  flex: 1;
  // background: url(../../assets/index/头像3.png);
  background-size: 70% !important;
  background-color: rgba(12,49,174,0.1) !important;
  background-position: center 20px !important;
}
.p-text {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f1f0f6;
}
.pan {
  font-size: 18px;
  font-weight: 600;
}
.pan-t {
  font-size: 14px;
  color: #7a7e8c;
}
.dai {
  font-size: 18px;
  font-weight: 600;
}
.dai-t {
  font-size: 14px;
  color: #7a7e8c;
}
.fangge {
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

/*  */
.m-content {
  background-color: #f1f0f6;
}
.c-content {
  background-color: #fff;
}
.m-under {
  width: 1280px;
  margin: 0 auto;
  padding: 60px 0;
}
.cooperate-pic{
  height: 394px;
}
.cp {
  padding-bottom: 20px;
}
.cp-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  .cp-say {
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(17, 17, 17, 0.85);
    span{
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(17, 17, 17, 0.45);
      margin-left: 4px;
    }
  }
  .cp-all {
    font-size: 12px;
    color: #0c31ae;
    cursor: pointer;
  }
}
.cp-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}
.cp-i {
  flex: 1;
  background: url(../../assets/index/产品1.png);
  background-size: cover;
}
.cp-text {
  flex: 1;
  padding: 35px 20px 0;
  background-color: white;
  border: 1px solid rgba(17, 17, 17, 0.14901960784313725);
  border-top: none;
  .cp-text-title{
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(17, 17, 17, 0.85);
  }
  .cp-text-content{
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 24px;
    color: rgba(17, 17, 17, 0.85);
    margin-top: 20px;
  }
  .cp-text-button{
    width: 102px;
    height: 38px;
    border: 1px solid #115DDB;
    float: right;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    text-align: center;
    color: #115DDB;
    line-height: 38px;
    margin-top: 20px;
  }
}
.cp-div .swiper-slide:hover .cp-text-button{
  background: #115DDB;
  color: #FFFFFF;
}
.cp-div .swiper-slide:hover .cp-text{
  box-shadow: 0px 0px 15px rgba(17, 93, 219, 0.15);
}
.text-top {
  padding: 0 50px 0 30px;
}
.text-tlt {
  padding: 20px 0;
  display: flex;
}
.l {
  color: #0c31ae;
  font-weight: 700;
  padding-right: 10px;
}
.l-tlt {
  font-size: 18px;
  font-weight: 600;
}
.l-text {
  color: #7a7e8c;
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  // text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 65px;
}
.icon {
  display: flex;
  justify-content: flex-end;
}
.i-span {
  width: 20px;
  height: 20px;
  background-color: #0c31ae;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: center;
}

/*  */
.resove {
  padding: 20px 0;
}
.res-t {
  font-size: 24px;
  font-weight: 700;
}
.res-a {
  color: #0c31ae;
  font-size: 12px;
  cursor: pointer;
}
.programme {
  display: flex;
  height: 400px;
}
.pro-left {
  flex: 1;
  background-color: #2b2682;
  margin-right: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.bg-i {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5%;
}
.leftdiv {
  margin: 20px 30px 0 30px;
  position: absolute;
  top: 0;
  left: 0;
}
.fangan {
  color: #fff;
  font-size: 24px;
  margin-bottom: 20px;
}
.engli {
  color: #9b96ee;
  font-size: 24px;
}
.pro-right {
  // flex: 2;
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  .pro-item {
    width: 48.9%;
    background-color: #fff;
    float: left;
    transition: margin-top 0.5s;
    cursor: pointer;
    .it-cont {
      margin: 22px 20px 22px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .it-top {
        margin-bottom: 15px;
        .it-tit {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .it-eng {
            font-size: 24px;
            font-weight: 600;
          }
          .more {
            color: #0c31ae;
            font-size: 12px;
          }
        }
        .computer {
          font-size: 24px;
        }
      }
      .it-but {
        height: 63px;
        color: #7a7e8c;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        // text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 14px;
        // /deep/ p{
        //   margin-top: 0;
        // }
      }
    }
  }
}
.pro-item:first-child {
  margin-bottom: 20px;
  margin-right: 2.2%;
}
.pro-item:nth-child(3) {
  margin-right: 2.2%;
}

.pro-item:nth-child(2) {
  margin-bottom: 20px;
}

/*  */
.f-content {
  width: 1280px;
  margin: 0 auto;
  padding: 40px 0 30px 0;
  height: 320px;
}
.lianx {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.f-flex {
  display: flex;
  height: 200px;
}
.kefu {
  flex: 1;
}
.f-title {
  font-size: 24px;
  font-weight: 700;
}
.gis {
  flex: 2;
  // background: url("../../assets/index/video.png");
  // background-size: cover;
}
.address {
  display: flex;
}
.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}
// 分页
.swiperpage {
  display: none;
}
.div-page {
  display: none;
}
.cp-div /deep/ .swiper-pagination-bullet{
  width: 28px;
  height: 8px;
  background: rgba(17, 93, 219, 0.2);
  border-radius: 0;
  opacity: 1;
  margin-right: 8px;
}
.cp-div /deep/ .swiper-pagination-bullet-active{
  width: 60px;
  height: 8px;
  background: #115DDB;
  opacity: 1;
}
.add-cont{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(17, 17, 17, 0.85);
  margin-top: 80px;
}

/* 媒体 */
@media screen and (max-width: 750px) {
  .solution-item{
    min-height: 130rem / @Font;
    .it-eng{
      font-size: 37rem / @Font;
      width: 230rem / @Font;
    }
    .more{
      font-size: 24rem / @Font;
    }
  }
  .swiperpage {
    display: block;
    // display: flex;
    // justify-content: center;
    text-align: center;
  }
  /deep/ .swiper-pagination-bullet {
    width: 25rem / @Font;
    height: 6rem / @Font;
    background: #bdbfc7;
    border-radius: 3rem / @Font;
  }
  /deep/ .swiper-pagination-bullet-active {
    background-color: #0c31ae;
  }
  .div-page {
    display: flex;
    justify-content: center;
    margin-top: 220rem / @Font;
  }
  .background {
    height: 920rem / @Font;
    .backg-text {
      margin-left: 50rem / @Font;
      padding-top: 15rem / @Font;
      .Logo {
        padding: 0;
        margin-bottom: 72rem / @Font;
        .logo-i {
          width: 215rem / @Font;
          height: 60rem / @Font;
          // opacity: 1;
        }
      }
      .Title {
        .title-i {
          width: 456rem / @Font;
          height: 165rem / @Font;
        }
      }
    }
  }
  .content {
    width: auto;
    margin: 0 46rem / @Font;
    padding-top: 200rem / @Font;
    padding-bottom: 56rem / @Font;
  }
  .rtive {
    display: block;
    height: auto;
    top: -520rem / @Font;
    .back-left {
      height: 320rem / @Font;
      .left-text {
        height: 226rem / @Font;
        padding: 0;
        margin: 50rem / @Font 0 48rem / @Font 44rem / @Font;
        .smoll-t {
          font-size: 32rem / @Font;
        }
        .fuh {
          padding: 20rem / @Font 0;
        }
        .smoll-j {
          font-size: 24rem / @Font;
        }
      }
    }
    .back-right {
      .right-item {
        height: 200rem / @Font;
        .itemcss {
          top: 16rem / @Font;
          left: 32rem / @Font;
          .topnum {
            font-size: 48rem / @Font;
          }
          .toptit {
            font-size: 28rem / @Font;
          }
          .botnum {
            font-size: 48rem / @Font;
          }
          .bottit {
            font-size: 28rem / @Font;
          }
        }
        .m-img {
          margin: 0 29rem / @Font 31rem / @Font 0;
          img {
            width: 132rem / @Font;
            height: 132rem / @Font;
          }
        }
        .r-img {
          margin: 0 29rem / @Font 29rem / @Font 0;
          img {
            width: 87rem / @Font;
            height: 87rem / @Font;
          }
        }
      }
    }
  }
  .renwu{
    background-size: auto 90% !important;
    background-position: center 100% !important;
  }
  /deep/ .swiper-slide {
    // width: 400rem / @Font !important;
    height: auto;
  }
  .imgbox,
  .swiperImg {
    height: 214rem / @Font;
    flex: none;
  }
  .text {
    height: 198rem / @Font;
    flex: none;
    padding: 25rem / @Font 0 23rem / @Font 32rem / @Font;
  }
  .guif {
    font-size: 32rem / @Font;
  }
  .hour {
    font-size: 24rem / @Font;
  }
  .el-button {
    display: none;
  }

  .under {
    padding-top: 56rem / @Font;
    .unf {
      margin: 0;
      padding-bottom: 56rem / @Font;
      .top {
        margin-bottom: 32rem / @Font;
        .xinwen {
          font-size: 40rem / @Font;
        }
        .all {
          font-size: 24rem / @Font;
        }
      }
    }
    .ke {
      .keyan {
        padding: 0;
        .tuand {
          font-size: 40rem / @Font;
        }
        .look {
          font-size: 24rem / @Font;
        }
      }
    }
  }

  // 产品
  .m-under {
    margin: 0;
    width: auto;
    padding: 32rem / @Font 46rem / @Font 0 46rem / @Font;
    .cp {
      padding: 0;
      margin-bottom: 66rem / @Font;
      .cp-title {
        margin-bottom: 32rem / @Font;
        .cp-say {
          font-size: 40rem / @Font;
        }
        .cp-all {
          font-size: 24rem / @Font;
        }
      }
      .cp-div {
        width: 100%;
        // height: 500rem / @Font;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        overflow-x: auto;

        .cp-text {
          flex: none;

          .text-top {
            padding: 0;
            margin: 32rem / @Font 154rem / @Font 0 44rem / @Font;
            .text-tlt {
              padding: 0;
              // margin-bottom: 44rem / @Font;
              .l {
                padding: 0;
                margin-right: 20rem / @Font;
                font-size: 30rem / @Font;
              }
              .l-tlt {
                font-size: 32rem / @Font;
              }
            }
            .l-text {
              font-size: 26rem / @Font;
              height: 2.6rem;
              /deep/ p{
                margin: 0;
              }
            }
          }
          .icon {
            .i-span {
              width: 56rem / @Font;
              height: 56rem / @Font;
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 25rem / @Font;
                height: 25rem / @Font;
              }
            }
          }
        }
      }
    }
  }

  // 地图
  .f-content {
    width: auto;
    margin: 0;
    padding: 56rem / @Font 46rem / @Font;
    .lianx {
      font-size: 40rem / @Font;
      margin-bottom: 32rem / @Font;
    }
    .f-flex {
      display: block;
      .kefu {
        .f-title {
          display: none;
        }
        .add-cont {
          .address,
          .youb,
          .phone {
            margin-bottom: 8rem / @Font;
            font-size: 26rem / @Font;
          }
          .qq {
            margin-bottom: 48rem / @Font;
            font-size: 26rem / @Font;
          }
        }
      }
      .gis {
        height: 320rem / @Font;
      }
    }
  }
}
</style>
