function formatDate(val) {
    var value=new Date(val);
    var year=value.getFullYear();
    var month=value.getMonth()+1;
    if(month<10) month = '0' + month; 
    var day=value.getDate();
    if(day<10) day = '0' + day;
    return year+'年'+month+'月'+day+'日';

}
export default formatDate;
