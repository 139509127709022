<template>
  <div class="footFlex">
    <div class="bg-img">
      <div class="logoimg">
        <div class="logo-top">
            <div>
                <div>
                    <img class="logo-i" src="../../assets/laboratory/Logo.png" alt />
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="navigation">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            团队
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <a>{{ team.name }}</a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <!-- <div class="team-img" :style="`background:url(http://47.92.65.216:8080/agile/sys/uploadDown/downImg?fileName=${team.image}) no-repeat`"></div> -->
        <!-- <div class="team-img" :style="`background:url(http://localhost:8080/lejiansoftwebbackwebback_war/sys/uploadDown/downImg?fileName=${team.image}) no-repeat`"></div> -->
        <div class="team-img" :style="`background:url(http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${team.image}) no-repeat`"></div>
        <div class="team-name">
          <div class="title-name">{{ team.name }}</div>
          <div class="pinyin-name">{{ team.pinyin }}</div>
          <div class="position-name">{{ team.title }}</div>
        </div>
        <div class="mobile" style="clear:both;"></div>
        <div class="CN-detail">
          <div class="title">简历</div>
          <div class="text" v-html="team.content"></div>
          <div class="title">荣誉简历</div>
          <div v-html="team.honor" class="honor"></div>
        </div>
        <div class="US-detail">
          <div class="title">Introduce</div>
          <div class="text" v-html="team.english"></div>
        </div>
      </div>
      <div style="clear:both;"></div>
    </div>
    <Footer class="foot"></Footer>
  </div>
</template>

<script>
import Footer from "../../components/footer";
import { detail } from "../../api/team"
import { pinyin } from "../../utils/Convert_Pinyin"
export default {
  name: "solutionDetail",
  components: {
    Footer,
  },
  data() {
    return {
      team:{}
    };
  },
  created () {
    detail(this.$route.query.id).then( res=> {
      this.team = res.team
      this.team.pinyin = pinyin.getFullChars(this.team.name)
    })
  },
  methods: {
    change(index){
      this.active = this.list[index].name
      this.index = index
    },
    mobileChange(key){
      if(key === 1) this.index = this.index - 1
      if(key === 2) this.index = this.index + 1
    },
    handleClick(){

    }
  },
};
</script>

<style scoped lang='less'>
@import "../../assets/css/media.css";
@Font: 50;
.footFlex{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .foot{
    margin-top: auto;
  }
}
.bg-img {
  display: flex;
//   height: 340px;
  display: none;
}
.logoimg {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo-top {
  height: 72px;
  background-color: #0c31ae;
  padding: 20px 0 0 15vw;
  color: #222222 !important;
  position: relative;
}
.english-tit {
  padding-top: 41px;
}
.j-img {
  position: absolute;
  bottom: 0;
  right: 13%;
}
.logo-i {
  width: 162px;
  height: 45px;
}

.color-t {
  display: flex;
  justify-content: flex-end;
  width: 400px;
  padding-left: 370px;
}

.content-box {
  width: 1280px;
  margin: 0 auto 80px;
}
.navigation {
  padding: 72px 0 32px 0;
}
.content {
  .team-img {
    height:336px;
    width:24%;
    float:left;
    // background: url(../../assets/team/人员1.png);
    // background-size: 100% 100% !important;
    background-size: auto 90% !important;
    background-position: center 100% !important;
    background-color: rgba(12,49,174,0.1) !important;
  }
  .team-name {
    height:167px;
    width:74%;
    margin-left:2%;
    float:left;
    border-top: 1px solid #E7E6ED;
    border-bottom: 1px solid #E7E6ED;
    padding-top: 30px;
    box-sizing: border-box;
    .title-name {
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 40px;
    }
    .pinyin-name {
      height: 24px;
      font-size: 20px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #222222;
      line-height: 24px;
      margin: 4px 0 17px 0;
    }
    .position-name {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #03C0C9;
      line-height: 22px;
    }
  }
  .CN-detail {
    float:left;
    width:38%;
    margin: 30px 3% 0 2%;
    .title {
      margin-bottom:16px;
    }
    .text {
      margin-bottom:40px;
    }
    .honor{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 32px;
    }
  }
  .US-detail {
    margin-top: 30px;
    float:left;
    width:33%;
    .text {
      line-height: 28px;
    }
  }
}
.mobile {
  display: none;
}
.title {
  height: 32px;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 32px;
}
.text {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 32px;
}

/* 媒体 */
@media screen and (max-width: 750px) {
  .navigation {
    display: none;
  }
  .bg-img {
    display: block;
  }
  .content {
    .team-img {
      height:336rem / @Font;
      width:40%;
      // background-size: 70% !important;
    }
    .team-name {
      height:336rem / @Font;
      width:49%;
      margin-left:3%;
      border-top: 1rem / @Font solid #E7E6ED;
      border-bottom: 1rem / @Font solid #E7E6ED;
      padding-top: 24rem / @Font;
      .title-name {
        height: 65rem / @Font;
        font-size: 46rem / @Font;
        line-height: 65rem / @Font;
      }
      .pinyin-name {
        height: 32rem / @Font;
        font-size: 28rem / @Font;
        line-height: 32rem / @Font;
        margin: 6rem / @Font 0 144rem / @Font 0;
      }
      .position-name {
        height: 40rem / @Font;
        font-size: 28rem / @Font;
        line-height: 40rem / @Font;
      }
    }
    .CN-detail {
      float:none;
      width:100%;
      margin: 56rem / @Font 0 0 0;
      .title {
        margin-bottom:20rem / @Font;
      }
      .text {
        margin-bottom:56rem / @Font;
      }
      .honor {
        font-size: 28rem / @Font;
        line-height: 44rem / @Font;
      }
    }
    .US-detail {
      margin-top: 56rem / @Font;
      float:none;
      width:100%;
      .title{
        margin-bottom: 20rem / @Font;
      }
    }
  }
  .title {
    height: 52rem / @Font;
    font-size: 40rem / @Font;
    line-height: 52rem / @Font;
  }
  .text {
    font-size: 28rem / @Font;
    line-height: 44rem / @Font;
  }
  .mobile {
    display: block;
  }
  .logo-top {
    height: 88rem / @Font;
    padding: 20rem / @Font 0 0 8vw;
    background: #fff;
  }
  .logo-i {
    width: 215rem / @Font;
    height: 60rem / @Font;
    display: none;
  }
  .tit-i {
    width: 291rem / @Font;
    height: 168rem / @Font;
  }
  .english-tit {
    padding-top: 40rem / @Font;
  }
  .j-img {
    display: none;
  }
  .color-t {
    display: none;
  }
  .bigimg {
    height: 320rem / @Font;
  }
  .content-box {
    width: auto;
    margin: 56rem / @Font 46rem / @Font 80rem / @Font;
  }
}
</style>
