import request from '@/utils/request'

export function newsList(page,limit) {
    return request({
        url: `/web/news/newsList?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

export function noticeList(page,limit) {
    return request({
        url: `/web/news/noticeList?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

export function detail(id) {
    return request({
        url: `/web/news/${id}`,
        method: 'get',
    })
}