<template>
  <div class="footFlex">
    <div class="bg-img">
      <div class="logoimg">
        <div class="logo-top">
          <div class="j-img"><img src="../../assets/news/photo.png" alt=""></div>
          <div>
            <div><img class="logo-i" src="../../assets/laboratory/Logo.png" alt=""></div>
            <div class="english-tit"><img class="tit-i" src="../../assets/news/title.png" alt=""></div>
          </div>
          
        </div>
        <div class="color-t"><img src="../../assets/product/彩条.png" alt=""></div>
      </div>
      <div class="bigimg"><span></span></div>
    </div>
    <div class="content">
      <div class="nav">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            <a>{{ title }}</a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="router">
        <div class="tab">
          <el-tabs :tab-position="position" @tab-click="handleClick">
            <el-tab-pane label="新闻动态"></el-tab-pane>
            <el-tab-pane label="通知公告"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="text-content">
          <router-view :type="type" v-if="routeIf"></router-view>
        </div>
      </div>
    </div>
    <Footer class="foot"></Footer>
  </div>
</template>

<script>
import Footer from '../../components/footer'
export default {
  name: "News",
  components: {
    Footer
  },
  data() {
    return {
      position: 'right',
      type: 1,
      routeIf: true,
      title:'新闻'
    };
  },
  mounted () {
    var medias = window.matchMedia('(max-width: 750px)')
    if (medias.matches) {
      this.position = 'top'
    }
  },
  methods: {
    handleClick (e) {
      if(e.index == 0){
        this.type = 1
        this.title = '新闻'
      }else{
        this.type = 2
        this.title = '公告'
      }
      this.routeIf = false 
      this.$nextTick(()=>{
        this.routeIf = true;
      });
    }
  },
};
</script>

<style scoped lang="less">
@Font: 50;
.footFlex{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .foot{
    margin-top: auto;
  }
}
.bg-img {
  display: flex;
  height: 340px;
}
.logoimg {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo-top {
  height: 300px;
  background-color: #0C31AE;
  padding: 20px 0 0 15vw;
  position: relative;
}
.english-tit {
  padding-top: 41px;
}
.j-img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.logo-i {
  width: 162px;
  height: 45px;
  opacity: 0;
}


.color-t {
  display: flex;
  justify-content: flex-end;
  width: 400px;
  padding-left: 370px;
}
.bigimg {
  flex: 2;
  background: url(../../assets/news/news.png);
  background-size: cover;
  position: relative;
  span {
      position: absolute;
      bottom: 0;
      width: 40px;
      height: 40px;
      background-color: #16E4DC;
  }
}
.content {
  width: 1280px;
  margin: 0 auto;
}
.nav {
  padding: 40px 0 32px 0;
}
/deep/ .el-tabs__nav-wrap::after {
  display: block;
}
/deep/ .el-tabs--right .el-tabs__header.is-right {
  margin: 0;
  float: none;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  font-size: 22px;
  color: #222222;
}
/deep/ .el-tabs__active-bar {
  width: 4px !important;
  background-color: #0C31AE;
}
/deep/ .el-tabs__item.is-active {
  color: #0C31AE;
}
.router {
  display: flex;
}
.tab {
  flex: 1;
}
.text-content {
  flex: 3;
}


// 媒体
@media screen and (max-width: 750px) {
  .nav {
    display: none;
  }
  .bg-img  {
    display: block;
    height: 100%;
  }
  .content {
    width: auto;
    margin: 0;
    padding-bottom: 56rem / @Font;
  }
  .logo-top {
    height: 280rem / @Font;
    padding: 15rem / @Font 0 30rem / @Font 50rem / @Font;
  }
  .logo-i {
    width: 215rem / @Font;
    height: 60rem / @Font;
    // opacity: 1;
  }
  .tit-i {
    width: 291rem / @Font;
    height: 168rem / @Font;
  }
  .english-tit {
    padding-top: 40rem / @Font;
  }
  .j-img {
    display: none;
  }
  .color-t {
    display: none;
  }
  .bigimg {
    display: none;
  }
  .router {
    display: block;
  }
  .text-content {
    margin: 56rem / @Font 46rem / @Font 0;
  }
  /deep/ .el-tabs__nav-scroll {
    overflow-x: auto !important;
    font-size: 32rem / @Font;
  }
  .el-tabs__nav-scroll::-webkit-scrollbar {
    display: none !important;
  }
  /deep/ .el-tabs__nav {
    padding: 28rem / @Font 0;
    background-color: #F1F0F6;
    width: 100%;
  }
  /deep/ .el-tabs__item {
    font-size: 32rem / @Font;
    height: 45rem / @Font;
    line-height: 45rem / @Font;
    padding: 0 40rem / @Font !important;
  }
  /deep/ .el-tabs__active-bar {
    width: 80rem / @Font !important;
    height: 6rem / @Font;
  }
  /deep/ .el-tabs__active-bar{
    left: 20rem / @Font;
  }
  /deep/ .el-tabs__header{
    margin: 0;
  }
}
</style>
