<template>
  <div class="footFlex">
    <div class="bg-img">
      <div class="logoimg">
        <div class="logo-top">
            <div>
                <div>
                    <img class="logo-i" src="../../assets/laboratory/Logo.png" alt />
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="navigation">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            研究
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <a>{{list[index].title}}</a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="left" v-html="list[index].details">
            <!-- <div class="left-title">{{list[index].name}}</div> -->     
        </div>
        <div class="right">
            <div class="right-title">{{type==1?'历年学术论文':type==2?'历年专利':type==3?'历年成果':type==4?'历年著作':''}}</div>
            <div class="right-bg"></div>
            <div :class="{'right-titles':true,'active':item.id == active}" v-for="(item,indexs) in list" :key="indexs" @click="change(indexs)">
                {{item.title}}
            </div>
        </div>
      </div>
      <div class="mobile-change">
            <div class="last" v-if="list[index-1]" @click="mobileChange(1)">上一篇<span>{{list[index-1].title}}</span></div>
            <div class="next" v-if="list[index+1]" @click="mobileChange(2)">下一篇<span>{{list[index+1].title}}</span></div>
      </div>
    </div>
    <Footer class="foot"></Footer>
  </div>
</template>

<script>
import Footer from "../../components/footer";
import { papers, papersDetail, patents, patentsDetail, achievements, achievementsDetail, publications, publicationsDetail } from "../../api/research"
export default {
  name: "researchDetail",
  components: {
    Footer,
  },
  data() {
    return {
      list:[],
      active:'',
      index:0,
      activeName:'first',
      id:'',
      type:''
    };
  },
  created () {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    if(this.type == 1){
      papers(1,100000).then(res =>{
        this.list = res.page.list
        this.active = this.id
        this.list.forEach((item,index) =>{
          if(item.id == this.id) this.index = index
        })
      })
    }else if(this.type == 2){
      patents(1,100000).then(res =>{
        this.list = res.page.list
        this.active = this.id
        this.list.forEach((item,index) =>{
          if(item.id == this.id) this.index = index
        })
      })
    }else if(this.type == 3){
      achievements(1,100000).then(res =>{
        this.list = res.page.list
        this.active = this.id
        this.list.forEach((item,index) =>{
          if(item.id == this.id) this.index = index
        })
      })
    }else if(this.type == 4){
      publications(1,100000).then(res =>{
        this.list = res.page.list
        this.active = this.id
        this.list.forEach((item,index) =>{
          if(item.id == this.id) this.index = index
        })
      })
    }
    
  },
  methods: {
    change(index){
      this.active = this.list[index].id
      this.index = index
    },
    mobileChange(key){
      if(key === 1) this.index = this.index - 1
      if(key === 2) this.index = this.index + 1
    },
    handleClick(){

    }
  },
};
</script>

<style scoped lang='less'>
@import "../../assets/css/media.css";
@Font: 50;
.footFlex{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .foot{
    margin-top: auto;
  }
}
.bg-img {
  display: flex;
//   height: 340px;
  display: none;
}
.logoimg {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo-top {
  height: 72px;
  background-color: #0c31ae;
  padding: 20px 0 0 15vw;
  color: #222222 !important;
  position: relative;
}
.english-tit {
  padding-top: 41px;
}
.j-img {
  position: absolute;
  bottom: 0;
  right: 13%;
}
.logo-i {
  width: 162px;
  height: 45px;
}

.color-t {
  display: flex;
  justify-content: flex-end;
  width: 400px;
  padding-left: 370px;
}

.content-box {
  width: 1280px;
  margin: 0 auto;
}
.navigation {
  padding: 72px 0 32px 0;
}
.content {
  padding-bottom: 80px;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}
.left {
    flex: 900;
    padding-right: 20px;
    overflow-x: auto;
    .left-title {
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 50px;
    }
    .left-list{
        padding: 20px 0;
    }
}
.right {
    flex: 280;
    padding: 24px 0 0 25px;
    .right-title {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 28px;
    }
    .right-bg {
        width: 16px;
        height: 1px;
        background: #0C31AE;
        margin: 22px 0 31px 0;
    }
    .right-titles {
        // height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 28px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    .active {
        color: #0C31AE;
    }
}
.mobile-change{
    display: none;
}
.detail /deep/ .el-tabs__item{
    height: 44px;
    font-size: 26px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    line-height: 32px;
} 
.detail /deep/ .el-tabs__item.is-active{
    color: #0C31AE;
}
.detail /deep/ .el-tabs__active-bar{
    width: 32px !important;
    height: 4px;
    left: 36px;
    background-color :#0C31AE;
}

/* 媒体 */
@media screen and (max-width: 750px) {
  .navigation {
    display: none;
  }
  .bg-img {
    display: block;
  }
  .content {
    padding-bottom: 0;
    display: block;
  }
  .logo-top {
    height: 88rem / @Font;
    padding: 20rem / @Font 0 0 8vw;
    background: #fff;
  }
  .logo-i {
    width: 215rem / @Font;
    height: 60rem / @Font;
    display: none;
  }
  .tit-i {
    width: 291rem / @Font;
    height: 168rem / @Font;
  }
  .english-tit {
    padding-top: 40rem / @Font;
  }
  .j-img {
    display: none;
  }
  .color-t {
    display: none;
  }
  .bigimg {
    height: 320rem / @Font;
  }
  .content-box {
    width: auto;
    margin: 56rem / @Font 46rem / @Font 0;
  }
  .left {
    font-size: 32rem / @Font;
    .left-title {
        height: 65rem / @Font;
        font-size: 46rem / @Font;
        line-height: 65rem / @Font;
    }
  }
  .right {
    display: none;
  }
  .mobile-change{
    display: block;
    margin: 80rem / @Font 0;
    .last,.next{
        height: 40rem / @Font;
        font-size: 28rem / @Font;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0C31AE;
        line-height: 40rem / @Font;
    }
    .last span,.next span {
        margin-left: 63rem / @Font;
    }
    .last {
        margin-bottom: 24rem / @Font;
    }
  }
  .detail /deep/ .el-tabs__item{
    height: 66rem / @Font;
    font-size: 32rem / @Font;
    line-height: 45rem / @Font;
  } 
  .detail /deep/ .el-tabs__active-bar{
    left: 0.48rem;
    width: 80rem / @Font !important;
    height: 6rem / @Font;
  }
}
</style>
