import request from '@/utils/request'

export function reward() {
    return request({
        url: `/web/scienceResearch/reward`,
        method: 'get',
    })
}

export function papers(page,limit) {
    return request({
        url: `web/scienceResearch/papers?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

export function papersDetail(id) {
    return request({
        url: `web/scienceResearch/papers/${id}`,
        method: 'get',
    })
}

export function patents(page,limit) {
    return request({
        url: `web/scienceResearch/patents?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

export function patentsDetail(id) {
    return request({
        url: `web/scienceResearch/patents/${id}`,
        method: 'get',
    })
}

export function achievements(page,limit) {
    return request({
        url: `web/scienceResearch/achievements?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

export function achievementsDetail(id) {
    return request({
        url: `web/scienceResearch/achievements/${id}`,
        method: 'get',
    })
}

export function publications(page,limit) {
    return request({
        url: `web/scienceResearch/publications?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

export function publicationsDetail(id) {
    return request({
        url: `web/scienceResearch/publications/${id}`,
        method: 'get',
    })
}