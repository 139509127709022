import axios from 'axios'
// create an axios instance
const service = axios.create({
  // baseURL: 'http://localhost:8080/agile_war_exploded', // url = base url + request url
  // baseURL: 'http://47.92.65.216:8080/agile',
  baseURL: 'http://47.92.65.216:8080/lejiansoftwebback',
  // baseURL: 'http://localhost:8080/lejiansoftwebbackwebback_war',
  timeout: 5000 // request timeout
})

service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */
  
    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
      const res = response.data
      console.log(res)
      return res
    }
)

export default service