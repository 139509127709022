<template>
    <div>
        <research-list :type="type"></research-list>
    </div>
</template>
<script>
import researchList from '../../components/researchList'
export default {
    name:"article",
    components: { researchList },
    data(){
        return {
            type:1
        }
    },
    methods(){}
}
</script>
<style lang="less" scoped>

</style>