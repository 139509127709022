import request from '@/utils/request'

export function list(page,limit) {
    return request({
        url: `/web/products/list?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

export function detail(id) {
    return request({
        url: `/web/products/${id}`,
        method: 'get',
    })
}

export function work() {
    return request({
        url: `/web/join/work`,
        method: 'get',
    })
}

export function project(id) {
    return request({
        url: `/web/home/itemInfo/${id}`,
        method: 'get',
    })
}

export function position() {
    return request({
        url: `/web/join/position`,
        method: 'get',
    })
}
