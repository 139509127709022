import request from '@/utils/request'

export function homeNews() {
    return request({
        // url: '/web/home/latestNews',
        url: '/web/home/banners',
        method: 'get',
    })
}

export function homeTeam() {
    return request({
        url: '/web/home/teamList',
        method: 'get',
    })
}

export function homeProduct() {
    return request({
        // url: '/web/home/products',
        url: '/web/home/items',
        method: 'get',
    })
}

export function homeSolution() {
    return request({
        url: '/web/home/solutions',
        method: 'get',
    })
}

export function homeBusiness() {
    return request({
        url: '/web/home/business',
        method: 'get',
    })
}