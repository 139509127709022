<template>
    <div class="article">
        <div class="item" @click="detail(item.id)" v-for="(item,index) in page.list" :key="index">
            <div class="left"><span>・</span>{{ item.title }}</div>
            <div class="right">
                {{ item.createTime | formatDate }}
                <div class="xiangq">查看详情
                    <span class="span-i"><img src="../assets/product/跳转白底x.png" alt=""></span>
                </div>
            </div>
        </div>
        <!-- 分页 -->
        <div class="pageDiv">
          <el-pagination
            :total="page.totalCount"
            :page-size="pageCount"
            :current-page.sync="page.currPage"
            layout="total, prev, pager, next"
            @current-change="pageChange"
          ></el-pagination>
        </div>
    </div>
</template>
<script>
import { papers, patents, achievements, publications } from '../api/research'
import formatDate from '../utils/dateFilter'
export default {
    name:"researchList",
    props:['type'],
    data(){
        return {
            page:{},
            pageCount:6
        }
    },
    filters:{
        formatDate
    },
    created(){
        if(this.type == 1) {
            papers(1,6).then(res => {
                this.page = res.page
            })
        }else if(this.type == 2){
            patents(1,6).then(res => {
                this.page = res.page
            })
        }else if(this.type == 3){
            achievements(1,6).then(res => {
                this.page = res.page
            })
        }else if(this.type == 4){
            publications(1,6).then(res => {
                this.page = res.page
            })
        }
    },
    methods:{
        detail(id) {
            this.$router.push({path:'/researchDetail',query:{type:this.type,id}})
        },
        pageChange(page){
            if(this.type == 1) {
                papers(page,this.pageCount).then(res => {
                    this.page = res.page
                })
            }else if(this.type == 2){
                patents(page,this.pageCount).then(res => {
                    this.page = res.page
                })
            }else if(this.type == 3){
                achievements(page,this.pageCount).then(res => {
                    this.page = res.page
                })
            }else if(this.type == 4){
                publications(page,this.pageCount).then(res => {
                    this.page = res.page
                })
            }
        },
    }
}
</script>
<style lang="less" scoped>
@import "../assets/css/media.css";
@Font: 50;
.item {
    padding: 18px 0 0 12px;
    height: 70px;
    border-bottom: 1px solid #E7E6ED;
    box-sizing: border-box;
    cursor: pointer;
}
.item:hover{
    background: #F9F9FB;
}
.left{
    float: left;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    line-height: 32px;
    span{
        display: none;
    }
}
.right{
    float: right;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 32px;
}
.xiangq { 
    font-family: PingFangTC-Regular, PingFangTC;
    font-weight: 400;
    color: #0C31AE;
    font-size: 14px;
    display: flex;
    float: right;
    margin-left: 24px;
}
.span-i {
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.pageDiv {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

/* 媒体 */
@media screen and (max-width: 750px) {
    .item {
        padding: 32rem / @Font 0 0 0;
        height: 96rem / @Font;
        border-bottom: 1rem / @Font solid #E7E6ED;
        
    }
    .article{
        margin-top: -32rem / @Font;
    }
    .left{
        font-size: 28rem / @Font;
        line-height: 28rem / @Font;
        span{
            display: inline;
        }
    }
    .right{
        font-size: 28rem / @Font;
        line-height: 28rem / @Font;
    }
    .xiangq { 
        display: none;
    }
    .text-content{margin: 0 !important;}
    // 分页
    .pageDiv {
        padding-bottom: 0;
        padding: 60rem / @Font 0 72rem / @Font 0;
        display: none;
    }
}
</style>