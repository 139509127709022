<template>
  <div class="footFlex">
    <div class="bg-img"></div>
    <div class="content">
      <div class="top">
        <div class="title">工作环境</div>
        <div class="swiper2 swiper-slide">
          <el-carousel :interval="1000000000" type="card" height="340px" >
            <el-carousel-item v-for="(el,index) in cpArr" :key="index" >
              <el-image
                style="width: 100%; height: 100%"
                :src="`http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${el.image}`"
                fit="cover">
              </el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="bottom">
        <div class="title">招聘职位</div>
        <div class="position-list">

        <div v-for="(el,index) in positionArr" :key="index"  @click="positionDetail(el)">
          <div class="list-item">
            <div class="item-left">
              <div class="left-title">{{el.title}}</div>
              <div class="left-descrption">
                <div>{{el.site}}</div>
                <div class="split"></div>
                <div>1人</div>
                <div class="split"></div>
                <div>{{el.ageLimit}}</div>
              </div>
              <div class="left-title">岗位描述</div>
              <div class="left-content">{{el.describe}}</div>
              <div class="left-detail">查看详情 >></div>
            </div>
            <div class="item-right">申请职位</div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <Footer class="foot"></Footer>
  </div>
</template>

<script>
import Footer from '../../components/footer';
import { work, position } from '../../api/product';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'


export default {
  name: 'product',
  components: {
    Footer
  },
  data() {
    return {
      cpArr:[
        { image:require('@/assets/product/蒙版组 17.png') },
        { image:require('../../assets/product/图1.png') },
        { image:require('../../assets/product/蒙版组 19.png') },
        { image:require('../../assets/product/蒙版组 18.png') }
      ],
      positionArr:[],
      swiperBut:''
    };
  },
  created() {
      work().then(res=>{
      console.log('positionArr : ', res)
      this.cpArr = res.list
      // this.cpArr = this.cpArr.concat(res.list)
    }),
      position().then(res=>{
      console.log('cpArr : ', res)
      this.positionArr = res.list
      // this.cpArr = this.cpArr.concat(res.list)
    })
  },
  mounted () {
    this.swipB()
  },
  methods: {
    swipB() {
      var mySwiper2 = new Swiper('.swiper2', {
        slidesPerView: 3,
        observer: true,
        observeParents: true,
        // spaceBetween: 40,
        effect : 'coverflow',
        centeredSlides: true,
        // loop:true,
        pagination : '.swiper-pagination',
        paginationClickable: true,
        uniqueNavElements :false,
        height:266,
        initialSlide :1
        // coverflow: {
        //     rotate: 50,
        //     stretch: 50,
        //     depth: 50,
        //     modifier: 1,
        //     slideShadows : true
        // }
      });
      this.swiperBut = mySwiper2;
    },

    positionDetail(el){
      console.log(el)
      this.$router.push({ path:'/PositionDetail', query:{ el } })
    }
  }
};
</script>

<style scoped lang='less'>
@import "../../assets/css/media.css";
@Font: 50;
.footFlex{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .content{
    width: 1280px;
    margin: 100px auto 120px;
    .top{

      .envirment{
        height: 340px;
        .swiper-slide {
          height: 340px;
          display: flex;
          flex-direction: column;
          box-shadow: 1px 1px 5px #eee;
          margin-top: 10px;
          cursor: pointer;
          transition: margin-top 0.5s;
        }
        .swiperImg {
          width: 100%;
          height: 100%;
        }
        .imgbox {
          // flex: 1;
          // overflow: hidden;
          height: 340px;
          flex: none;
        }
        .productImg{
          background-size: auto 100% !important;
          background-position: center !important;
          background-color:#e7e9ed !important;
        }
      }
    }
    .bottom{
      .title{
        margin-top: 100px;
      }
      .position-list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .list-item{
          cursor: pointer;
          margin-bottom: 48px;
          box-sizing: border-box;
          width: 610px;
          height: 233px;
          border: 1px solid #CCCCCC;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          padding: 21px 36px 0;
          .item-left{
            width: 404px;
            .left-title{
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
            }
            .left-descrption{
              margin: 12px 0 20px 0;
              display: flex;
              justify-content: space-between;
              width: 120px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: rgba(17, 17, 17, 0.6);
              height: 12px;
              line-height: 12px;
              .split{
                width: 1px;
                height: 12px;
                background: rgba(17, 17, 17, 0.1);
                opacity: 1;
              }
            }
            .left-content{
              height: 50px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 24px;
              color: #333333;
              overflow: hidden;
              margin: 9px 0 20px 0;
            }
            .left-detail{
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #115DDB;
            }
          }
          .item-right{
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 38px;
            color: #115DDB;
            width: 102px;
            height: 38px;
            border: 1px solid #115DDB;
            box-shadow: 0px 3px 13px rgba(6, 31, 66, 0.42);
            text-align: center;
            margin-top: 6px;
          }
        }
      }
    }
  }
  .list-item:hover .item-right{
    background: #115DDB;
    color: #FFFFFF !important;
  }
  .list-item:hover {
    box-shadow: 0px 0px 15px rgba(17, 93, 219, 0.15) !important;
  }
  .foot{
    margin-top: auto;
  }
}
.title{
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(17, 17, 17, 0.85);
  margin-bottom: 56px;
  text-align: center;
}
.bg-img {
  height: 380px;
  background: url("../../assets/introduce/组 70.png");
  background-size: cover;
  background-size: 100% 100%;
  margin-top: 76px;
}


  .el-carouse__item:nth-child(2n) {
    width: 50%;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

/* 媒体 */
@media screen and (max-width: 750px){
  .navigation {
    display: none;
  }
  .bg-img {
    display: block;
    height: 100%;
  }
  .content {
    padding-bottom: 0;
  }
  .logo-top {
    height: 280rem / @Font;
    padding: 15rem / @Font 0 30rem / @Font 50rem / @Font;
    // background: #fff;
  }
  .logo-i {
    width: 215rem / @Font;
    height: 60rem / @Font;
    // opacity: 1;
  }
  .tit-i {
    width: 291rem / @Font;
    height: 168rem / @Font;
  }
  .english-tit {
    padding-top: 40rem / @Font;
  }
  .j-img {
    display: none;
  }
  .color-t {
    display: none;
  }
  .bigimg {
    display: none;
  }
  .content-box {
    width: auto;
    margin: 56rem / @Font 46rem / @Font 0;
  }
  .item {
    width: 100%;
    display: block;
    height: auto;
    margin-bottom: 12rem / @Font;
    box-shadow: none;
  }
  .item-content :nth-child(3n+2){
    margin:0;
  }
  .bgi {
    height: 346rem / @Font;
  }
  .text-box {
    overflow: hidden;
    padding: 62rem / @Font 0 36rem / @Font 12rem / @Font;
    height: 350rem / @Font;
  }
  .title {
    font-size: 48rem / @Font;
    margin-bottom: -1rem;
  }
  .text {
    font-size: 34rem / @Font;
    // margin: 42rem / @Font 0 61rem / @Font !important;
    height: 2.6rem;
  }
  .xiangq {
    font-size: 24rem / @Font;
  }
  .span-i {
    padding-left: 20rem / @Font;
  }
  .span-i > img {
    width: 25rem / @Font;
    height: 25rem / @Font;
  }

  // 分页
  .pageDiv {
    padding-bottom: 0;
    padding: 60rem / @Font 0 72rem / @Font 0;
    display: none;
  }
  .loading {
    display: flex;
    justify-content: center;
    margin: 0.32rem 0 40rem / @Font 0;
    font-size: 24rem / @Font;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7A7E8C;
    line-height: 33rem / @Font;
    .icon-box{
      width: 32rem / @Font;
      height: 32rem / @Font;
      box-sizing: border-box;
      border-radius: 20rem / @Font;
      margin-right: 16rem / @Font;
      background-image: linear-gradient(30deg , #FFFFFF , #0C31AE);
      -webkit-transition-property: -webkit-transform;
      -webkit-transition-duration: 1s;
      -moz-transition-property: -moz-transform;
      -moz-transition-duration: 1s;
      -webkit-animation: rotate 3s linear infinite;
      -moz-animation: rotate 3s linear infinite;
      -o-animation: rotate 3s linear infinite;
      animation: rotate 3s linear infinite;
      .icon{
        width: 16rem / @Font;
        height: 16rem / @Font;
        background: #FFFFFF;
        border-radius: 20rem / @Font;
        margin: 8rem / @Font 0 0 8rem / @Font;
      }
    }
  }
  .el-pagination {
    width: 649rem / @Font;
    height: 40rem / @Font;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/ .el-pagination .btn-prev {
    padding: 0;
  }
  /deep/ .el-pagination .btn-next {
    padding: 0;
  }
  /deep/ .el-pagination__total {
    font-size: 32rem / @Font !important;
    margin-right: 10rem / @Font;
  }
  /deep/ .el-pager .number {
    font-size: 32rem / @Font !important;
  }
  /deep/ .el-icon {
    font-size: 32rem / @Font !important;
  }

  .el-carousel__item h3 {
    font-size: 14px;
    opacity: 0.75;
    line-height: 340px;
    margin: 0;
  }

}
</style>
