import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import BaiduMap from 'vue-baidu-map';
import './plugins/element.js'

import './assets/css/global.css'
import "./assets/css/media.css"

import axios from 'axios'


// -------
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)
// --------

document.title='成都乐见数字科技有限公司'

Vue.prototype.$http = axios

Vue.config.productionTip = false

// Vue.use(BaiduMap, {
//   /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
//   ak: 'H9e6FKnGy80pvXRfYKkUYnk41wzXRuZE'
// })

new Vue({
  router,
  render: h => h(App),
// -----------
components: { App },

}).$mount('#app')
