import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/tabs/index.vue'
import Product from '../views/tabs/product.vue'
import ProductDetail from '../views/product_c/detail.vue'
// import PositionDetail from '../views/position_c/detail.vue'
import Solution from '../views/tabs/solution.vue'
import SolutionDetail from '../views/solution_c/detail.vue'
import Team from '../views/tabs/team.vue'
import TeamDetail from '../views/team_c/detail.vue'
import Cooperate from '../views/tabs/cooperate.vue'
import CooperateDetail from '../views/cooperate_c/detail.vue'

import Research from '../views/tabs/research.vue'
import ResearchDetail from '../views/research_c/detail.vue'
import Awards from '../views/research_c/awards.vue'
import Achievement from '../views/research_c/achievement.vue'
import Article from '../views/research_c/article.vue'
import Equipment from '../views/research_c/equipment.vue'
import Patent from '../views/research_c/patent.vue'
import Publish from '../views/research_c/publish.vue'

import Introduce from '../views/tabs/introduce.vue'
import News from '../views/tabs/news.vue'
import NewsDetail from '../views/news_c/detail.vue'
import Development from '../views/news_c/development.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
    name: 'Home',
    component: Home,
    children: [//tab页
      {
        path: '/index',
        component: Index
      },
      {
        path: '/introduce',
        component: Introduce,
      },
      {
        path: '/product',
        component: Product
      },
      {
        path: '/productDetail',
        component: ProductDetail
      },
      // {
      //   path: '/positionDetail',
      //   component: PositionDetail
      // },
      {
        path: '/solution',
        component: Solution
      },
      {
        path: '/solutionDetail',
        component: SolutionDetail
      },
      {
        path: '/cooperate',
        component: Cooperate
      },
      {
        path: '/cooperateDetail',
        component: CooperateDetail
      },
      {
        path: '/team',
        component: Team
      },
      {
        path: '/teamDetail',
        component: TeamDetail
      },
      {
        path: '/research',
        component: Research,
        redirect: '/research/awards',
        children: [
          {
            path: 'awards',
            component: Awards
          },
          {
            path: 'achievement',
            component: Achievement
          },
          {
            path: 'article',
            component: Article
          },
          {
            path: 'equipment',
            component: Equipment
          },
          {
            path: 'patent',
            component: Patent
          },
          {
            path: 'publish',
            component: Publish
          },
        ]
      },
      {
        path: '/researchDetail',
        component: ResearchDetail
      },
      {
        path: '/news',
        component: News,
        redirect: '/news/development',
        children: [
          {
            path: 'development',
            component: Development
          }
        ]
      },
      {
        path: '/newsDetail',
        component: NewsDetail
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = '成都乐见数字科技有限公司'
  next()
})

export default router
