<template>
  <div class="footFlex">
    <div class="bg-img">
      <div class="logoimg">
        <div class="logo-top">
          <div class="j-img">
            <img src="../../assets/solution/蒙版.png" alt />
          </div>
          <div>
            <div>
              <img class="logo-i" src="../../assets/laboratory/Logo.png" alt />
            </div>
            <div class="english-tit">
              <img class="tit-i" src="../../assets/solution/解决方案标题.png" alt />
            </div>
          </div>
        </div>
        <div class="color-t">
          <img src="../../assets/product/彩条.png" alt />
        </div>
      </div>
      <div class="bigimg"><span></span></div>
    </div>
    <div class="content-box">
      <div class="navigation">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            <a>解决方案</a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="item-content">
          <div class="item itemHover" @click="detail(item.id)" v-for="(item,index) in page.list" :key="index">
            <!-- <div class="bgi" :style="`background:url(http://47.92.65.216:8080/agile/sys/uploadDown/downImg?fileName=${item.image}) no-repeat`"> -->
             <!-- <div class="bgi" :style="`background:url(http://localhost:8080/lejiansoftwebbackwebback_war/sys/uploadDown/downImg?fileName=${item.image}) no-repeat`"> -->
             <div class="bgi" :style="`background:url(http://47.92.65.216:8080/lejiansoftwebback/sys/uploadDown/downImg?fileName=${item.image}) no-repeat`">
              <div class="title">{{ item.title }}</div>
              <div class="xiangq">查看详情
              <span class="span-i"><img src="../../assets/product/跳转白底x.png" alt=""></span>
              </div>
            </div>
            <div class="text-box">
              <div class="text" v-html="item.content"></div>
              <div class="xiangqHover" style="background:none;padding:0;margin:0;">查看详情
                <span class="span-i"><img src="../../assets/product/跳转白底x.png" alt=""></span>
              </div>
            </div>
            <img src="../../assets/solution/编组 2.png" class="item-bg">
          </div>
        </div>
        <!-- 分页 -->
        <div class="pageDiv">
          <el-pagination
            :total="page.totalCount"
            :page-size="pageCount"
            :current-page.sync="page.currPage"
            layout="total, prev, pager, next"
            @current-change="pageChange"
          ></el-pagination>
        </div>
        <div v-if="loading" class="loading">
          <div class="icon-box">
            <div class="icon"></div>
          </div>
          加载中…
        </div>
        <div v-if="noMore" class="loading">没有更多内容了</div>
      </div>
    </div>
    <Footer class="foot"></Footer>
  </div>
</template>

<script>
import Footer from "../../components/footer";
import { list } from "../../api/solution"
import { getScrollTop, getScrollHeight, getWindowHeight } from '../../api/scroll'
let timer;
export default {
  name: "solution",
  components: {
    Footer,
  },
  data() {
    return {
      page: {},
      loading: false,
      noMore: false,
      pageCount: 9
    };
  },
  methods:{
    detail(id){
      this.$router.push({path:'/solutionDetail',query:{id}})
    },
    pageChange(page){
      list(page,this.pageCount).then(res =>{
        this.page = res.page
      })
    },
    funcScroll(e) { 
      if(Math.abs(Math.round(getScrollTop()) + getWindowHeight() - getScrollHeight()) <= 1){
        if(!timer){
          if(this.page.currPage == this.page.totalPage) {
            this.noMore = true
            timer = setTimeout(()=>{
              this.noMore = false
              document.body.scrollTop = document.documentElement.scrollTop = document.documentElement.scrollTop-60
              window.clearTimeout(timer)
              timer=undefined
            },2000)
          }else{
            this.loading = true
            list(this.page.currPage+1,this.pageCount).then(res=>{
              this.page.list = this.page.list.concat(res.page.list)
              this.page.currPage = res.page.currPage
              this.page.pageSize = res.page.pageSize
              this.page.totalCount = res.page.totalCount
              this.page.totalPage = res.page.totalPage
              this.loading = false
              document.body.scrollTop = document.documentElement.scrollTop = document.documentElement.scrollTop-60
            })
          }
        }
        
      }
    }
  },
  created(){
    
  },
  mounted () {
    var medias = window.matchMedia('(max-width: 750px)')
    if (medias.matches) {
      this.pageCount = 3
      window.addEventListener('scroll', this.funcScroll);
      setTimeout(()=>{
        let list = Array.from(document.getElementsByClassName('itemHover'))
        list.forEach(item=>{
          item.className='item'
          console.log(item.className)
        })
      },200)
    }
    list(1,this.pageCount).then(res => {
      this.page = res.page
    })
  },
  updated() {
    var medias = window.matchMedia('(max-width: 750px)')
    if (medias.matches) {
      setTimeout(()=>{
        let list = Array.from(document.getElementsByClassName('itemHover'))
        list.forEach(item=>{
          item.className='item'
        })
      },200)
    }
  }
};
</script>

<style scoped lang='less'>
@import "../../assets/css/media.css";
@Font: 50;
.footFlex{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .foot{
    margin-top: auto;
  }
}
.bg-img {
  display: flex;
  height: 340px;
}
.logoimg {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo-top {
  height: 300px;
  background-color: #0c31ae;
  padding: 20px 0 0 15vw;
  position: relative;
}
.english-tit {
  padding-top: 41px;
}
.j-img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.logo-i {
  width: 162px;
  height: 45px;
  opacity: 0;
}

.color-t {
  display: flex;
  justify-content: flex-end;
  width: 400px;
  padding-left: 370px;
}
.bigimg {
  flex: 2;
  background: url(../../assets/solution/VCG21409095263.png);
  background-size: cover;
  position: relative;
  span {
      position: absolute;
      bottom: 0;
      width: 40px;
      height: 40px;
      background-color: #16E4DC;
  }
}
.content-box {
  width: 1280px;
  margin: 0 auto;
}
.navigation {
  padding: 40px 0 32px 0;
}
.content {
  padding-bottom: 80px;
}
.item-content {
  display: flex;
  // justify-content: space-between;
  flex-flow: wrap;
}
.item {
  width: 32%;
  height: 425px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0 0 5px #eee;
  .xiangqHover{
    display: none;
  }
  .item-bg{
    display: none;
  }
}
.itemHover:hover{
  cursor: pointer;
  background-color: #F1F0F6;
  position:relative;
  .bgi{
    flex: 1;
    background: none !important;
    .title{
      margin-top: 32px;
      background: none;
    }
    .xiangq{
      display: none;
    }
  }
  .text-box{
    flex: 3;
    margin-top: -30px;
    .xiangqHover{
      display: inherit;
    }
  }
  .item-bg{
    position:absolute;
    width: 50%;
    right: 0;
    bottom: 0;
    display: block;
  }
}
.item-content :nth-child(3n+2){
  margin:0 2%;
}
.bgi {
  // flex: 6;
  // flex-grow: 0;
  height: 233px;
  // background: url(../../assets/timg.jpg);
  // background-size: 100% 100% !important;
  overflow: hidden;
  background-size: auto 80% !important;
  background-position: center !important;
  background-color: #e7e9ed !important;
}
.text-box {
  flex: 2;
  // padding: 30px 30px 20px 10px;
  padding: 12px 16px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  font-size: 28px;
  font-weight: 700;
  color: #222222;
  background: #ffffff;
  width: 224px;
  padding: 6px 8px 6px 8px;
  margin: 10px 0 0 10px;
}
.text {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  // text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 20px;
  font-weight: 400;
  color: #6C6F7B;
  line-height: 36px;
  height: 111px;
}
.xiangq,.xiangqHover {
  color: #0C31AE;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  background: #ffffff;
  width: 76px;
  padding: 6px 8px 6px 8px;
  margin-left: 10px !important;
}
.span-i {
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.pageDiv {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

/* 媒体 */
@media screen and (max-width: 750px) {
  .navigation {
    display: none;
  }
  .bg-img {
    display: block;
    height: 100%;
  }
  .content {
    padding-bottom: 0;
  }
  .logo-top {
    height: 280rem / @Font;
    padding: 15rem / @Font 0 30rem / @Font 50rem / @Font;
  }
  .logo-i {
    width: 215rem / @Font;
    height: 60rem / @Font;
    // opacity: 1;
  }
  .tit-i {
    width: 291rem / @Font;
    height: 168rem / @Font;
  }
  .english-tit {
    padding-top: 40rem / @Font;
  }
  .j-img {
    display: none;
  }
  .color-t {
    display: none;
  }
  .bigimg {
    height: 320rem / @Font;
    display: none;
  }
  .content-box {
    width: auto;
    margin: 56rem / @Font 46rem / @Font 0;
  }
  .item {
    width: 100%;
    display: block;
    height: auto;
    margin-bottom: 12rem / @Font;
    box-shadow: none;
  }
  .item-content :nth-child(3n+2){
    margin:0;
  }
  .bgi {
    height: 432rem / @Font;
  }
  .text-box {
    // padding: 62rem / @Font 0 36rem / @Font 12rem / @Font;
    // padding: 0 0 0 12rem / @Font;
    overflow: hidden;
    height: 190rem / @Font;
    border-bottom: 1rem / @Font solid #E7E6ED;
    max-height: 301rem / @Font;
    padding: 42rem / @Font 0 61rem / @Font 12rem / @Font;
    /deep/ p{
      margin:0;
    }
  }
  .title {
    font-size: 48rem / @Font;
    width: 386rem / @Font;
  }
  .text {
    font-size: 34rem / @Font;
  }
  .xiangq {
    font-size: 24rem / @Font;
    width: 152rem / @Font;
  }
  .span-i {
    padding-left: 20rem / @Font;
  }
  .span-i > img {
    width: 25rem / @Font;
    height: 25rem / @Font;
  }

  // 分页
  .pageDiv {
    padding-bottom: 0;
    padding: 60rem / @Font 0 72rem / @Font 0;
    display: none;
  }
  .el-pagination {
    width: 649rem / @Font;
    height: 40rem / @Font;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading {
    display: flex;
    justify-content: center;
    margin: 0.32rem 0 40rem / @Font 0;
    font-size: 24rem / @Font;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7A7E8C;
    line-height: 33rem / @Font;
    .icon-box{
      width: 32rem / @Font;
      height: 32rem / @Font;
      box-sizing: border-box;
      border-radius: 20rem / @Font;
      margin-right: 16rem / @Font;
      background-image: linear-gradient(30deg , #FFFFFF , #0C31AE);
      -webkit-transition-property: -webkit-transform;
      -webkit-transition-duration: 1s;
      -moz-transition-property: -moz-transform;
      -moz-transition-duration: 1s;
      -webkit-animation: rotate 3s linear infinite;
      -moz-animation: rotate 3s linear infinite;
      -o-animation: rotate 3s linear infinite;
      animation: rotate 3s linear infinite;
      .icon{
        width: 16rem / @Font;
        height: 16rem / @Font;
        background: #FFFFFF;
        border-radius: 20rem / @Font;
        margin: 8rem / @Font 0 0 8rem / @Font;
      }
    }
  }
  /deep/ .el-pagination .btn-prev {
    padding: 0;
  }
  /deep/ .el-pagination .btn-next {
    padding: 0;
  }
  /deep/ .el-pagination__total {
    font-size: 32rem / @Font !important;
    margin-right: 10rem / @Font;
  }
  /deep/ .el-pager .number {
    font-size: 32rem / @Font !important;
  }
  /deep/ .el-icon {
    font-size: 32rem / @Font !important;
  }
}
</style>
